// import VCheckbox from 'vuetify/es5/components/VCheckbox'
import { VCheckbox } from 'vuetify2/lib'

export default {
  name: 'accept-agb',
  extends: VCheckbox,
  methods: {
    genMyLabel() {
      const label = this.genLabel()
      if (label) {
        return label
      } else {
        return this.$createElement('span', { on: { click: this.onChange }}, [
          '* Die ',
          this.$createElement('a', { on: { click: e => e.stopPropagation() }, attrs: { href: "/agb", target: "_blank" } }, 'Vertragsbedingungen/AGB'),
          ' zur JugendFEIER habe ich gelesen und bin mit ihnen einverstanden.'
        ])
      }
    },
    genDefaultSlot() {
      return [
        this.genCheckbox(),
        this.genMyLabel()
      ]
    }
  }
}
