/* eslint no-console: 0 */
import "core-js/stable"
import 'whatwg-fetch'
import Vue from 'vue'
import Vuetify from 'vuetify2'
import PortalVue from 'portal-vue'
import VueResource from 'vue-resource'
import Cookie from 'cookie'
import VueGtag from 'vue-gtag'
// import VuePortal from '@linusborg/vue-simple-portal'

import de from '../backend/locale/de'
import router from '../frontend2/router'
import store from '../frontend/store'
import apolloProvider from '../frontend/store/apollo-provider'
import '../frontend/components'
import '../frontend2'
import moment from 'moment'
moment.locale('de')
Vue.config.productionTip = false

if (store.state.gtagId) {
  Vue.use(VueGtag, {
    config: { id: store.state.gtagId, params: { anonymize_ip: true } },
    bootstrap: store.state.gtagEnabled === 'enabled',
  },
  router)
}

Vue.use(PortalVue)
// Vue.use(VuePortal, { name: 'simple-portal' })
Vue.use(Vuetify)
const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
      variations: true,
    },
    themes: {
      light: {
        primary: '#ec7404',
        secondary: '#5B9BD5',
        accent: '#0053a6',
        error: '#B71C1C',
        info: '#0D47A1',
        success: '#2E7D32',
        warning: '#FFB300'
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
})

Vue.use(VueResource)
Vue.http.interceptors.push((request, next) => {
  const token = Cookie.parse(document.cookie).authenticity_token
  if (token && request.url && (request.url.startsWith(location.origin) || /^\/([^/]|$)/.test(request.url))) {
    request.headers.set('X-CSRF-Token', token)
  }
  next()
})

new Vue({
  router,
  store,
  apolloProvider,
  vuetify,
  render: h => h('router-view')
}).$mount("#frontend2")
