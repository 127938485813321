import { admin } from '../utils/api'

export default {
  data() {
    return {
      stapelActionLoading: 0
    }
  },
  methods: {
    confirmStapel(msg) {
      return confirm(`${msg} wirklich für ${this.selected.length} ausgewählte ausführen?`)
    },
    confirmSingle(msg, name) {
      return confirm(`${msg} wirklich${name ? ` für ${name}` : ''} ausführen?`)
    },
    promptStapel(msg, resource, action, input) {
      const val = prompt(`${msg} für ${this.selected.length} ausgewählte?`)
      if (val !== null) {
        const data = {}
        data[input] = val
        this.stapelAction(resource, action, data)
      }
    },
    stapelAction(resource, action, inputs, selected) {
      const data = {
        batch_action: action,
        batch_action_inputs: JSON.stringify(inputs || {}),
        collection_selection: selected || this.selected.map(s => s.id)
      }
      this.stapelActionLoading++
      return admin.post(`${resource}/batch_action.json`, data).then(response => {
        this.$store.commit('addSuccess', { text: response.data.message || 'Aktion erfolgreich', timeout: 2000 })
        this.stapelActionLoading--
      }, error => {
        const errors = error.response.data && error.response.data.errors && error.response.data.errors.join(', ')
        this.$store.commit('addError', { text: errors || 'Ein Fehler ist aufgetreten', timeout: 0 })
        this.stapelActionLoading--
      })
    }
  }
}
