<template lang="pug">
.mb-6
  v-img(:src="value.bild" :aspectRatio="value.aspectRatio" v-if="value.bild")
  .px-2(v-html="value.html")
  template(v-if="user")

    template(v-if="vpUrls.length")
      v-card.mb-3.mt-6: v-card-title.title.grey.lighten-3 Hier geht es zum Vorbereitungsprogramm
      v-card.mb-3
        v-card-text.title
          template(v-for="url in vpUrls")
            |  &#32;
            a(:href="url.url") {{url.name}}

    v-card.mb-3.mt-6: v-card-title.title.grey.lighten-3 Vertragsdaten
    v-card.mb-3
      v-card-text
        v-row(wrap)
          v-col(cols="12" sm="6"): v-text-field(label="E-Mail" v-model="user.email" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Telefonnummer" hint="(mobil/tagsüber)" persistent-hint v-model="user.telefon" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Vorname (Vertragspartner)" v-model="user.vorname" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Nachname (Vertragspartner)" v-model="user.nachname" disabled hide-details)
          v-col(cols="12"): v-text-field(label="Adresszusatz" v-model="user.adresszusatz" disabled hint="(z. B. c/o, abweichender Name am Briefkasten oder andere zur Zustellung notwendige Angabe)" persistent-hint hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Straße" v-model="user.strasse" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Hausnummer" v-model="user.strasseNr" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="PLZ" v-model="user.plz" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Ort" v-model="user.ort" disabled hide-details)
          v-col(cols="12" v-if="edit.sozialrabattBeantragt")
            v-checkbox(label="Ich erhalte ALG II oder ich und / oder mein Kind besitze/n den Berlinpass und möchte/n den Sozialrabatt in Anspruch nehmen." v-model="edit.sozialrabattBeantragt" disabled)
            template(v-if="!edit.sozialrabattBestaetigt && edit.sozialausweisKommt === 'later'")
              v-radio-group.mt-0(v-model="edit.sozialausweisKommt")
                v-radio.mb-0(label="Den entsprechenden Nachweis sende ich Ihnen innerhalb von 7 Tagen per Post, Fax oder E-Mail." value="later" disabled)
              p
                | Postadresse: {{constants && constants.infoPost}}<br>
                | Fax: {{constants && constants.infoFax}}, E-Mail: {{constants && constants.infoMail}}

    v-card.mb-3.mt-6: v-card-title.title.grey.lighten-3
      | {{teilnehmer.length}} Teilnehmer
      v-spacer
      v-checkbox.mt-0(label="Stornierte anzeigen" v-if="stornierteTn > 0" v-model="zeigeStornierte" hide-details)
    v-card.mb-1(v-for="tn in teilnehmer" :key="tn.id")
      v-card-text
        v-row(wrap)
          v-col(cols="12" sm="6" lg="4"): v-text-field(label="Vorname der_s Jugendlichen" v-model="tn.vorname" disabled hide-details)
          v-col(cols="12" sm="6" lg="4"): v-text-field(label="Nachname der_s Jugendlichen" v-model="tn.nachname" disabled hide-details)
          v-col(cols="12" sm="6" lg="4" :order="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.xs  ? 1 : 3"): v-text-field(label="Gruppe" :value="tn.gruppe && `${tn.gruppe.xid}: ${tn.gruppe.vollerName}` || ' '" disabled hide-details)
          v-col(cols="12" sm="6" :order="2"): v-text-field(label="Termin" :value="datumZeit(tn.termin.zeit)" disabled hide-details)
          v-col(cols="12" sm="6" :order="2"): v-text-field(label="Location" :value="tn.termin.ort.name" disabled hide-details)
          v-col(cols="12" sm="6" lg="4" :order="2"): v-text-field(label="Vorbereitungsprogramm" :value="tn.vorbereitungsprogramm" disabled hide-details)
          v-col(cols="12" sm="6" lg="4" :order="2"): v-text-field(label="Gästekarten" :value="tn.total" disabled hide-details)
          v-col(cols="12" sm="6" lg="4" :order="4"): v-btn(block @click="tnDetails = tn") Details

    v-card.mb-3.mt-6(v-if="user && user.gruppen.length"): v-card-title.title.grey.lighten-3
      | Gruppe{{user.gruppen.length  == 1 ? '' : 'n'}}
    v-card.mb-1(v-for="gruppe in user.gruppen" :key="gruppe.id")
      v-card-text
        v-row(wrap)
          v-col(cols="12" sm="2"): v-text-field(label="Nr." v-model="gruppe.xid" disabled hide-details)
          v-col(cols="12" sm="10"): v-text-field(label="Name" v-model="gruppe.vollerName" disabled hide-details)
          v-col(cols="12" sm="4"): v-text-field(label="Teilnehmer Angemeldet" :value="gruppe.teilnehmer.filter((t) => t.status === 'Gebucht').length" disabled hide-details)
          v-col(cols="12" sm="4"): v-text-field(label="Plätze Reserviert" :value="gruppe.teilnehmer.filter((t) => t.status === 'Reserviert').length" disabled hide-details)
          v-col(cols="12" sm="4"): v-text-field(label="Reserviert Bis" :value="reserviertBis(gruppe)" disabled hide-details)
          v-col(cols="12" sm="8")
            strong Gruppenlink
            br
            a.text-body-1(:href="gruppe.gruppenlink") {{gruppe.gruppenlink}}
          v-col(cols="12" sm="4"): v-btn(block @click="gruppeDetails = gruppe") Details

    template(v-for="liste in wartelisten")
      v-card.mb-3.mt-6: v-card-title.title.grey.lighten-3 {{ liste.voranmeldung ? 'Voranmeldung' : 'Warteliste' }}
      template(v-for="warteliste in liste.liste")
        v-card.mb-1
          v-card-text
            strong {{warteliste.anzahlTeilnehmer}} {{warteliste.anzahlTeilnehmer == 1 ?  'Platz' : 'Plätze'}}
            template(v-if="warteliste.termine[0]")
              |  {{warteliste.termine[0].ort.adverbial}}
              strong  {{warteliste.termine[0].ort.name}}
            div {{tage(warteliste.termine)}}

  v-dialog(:value="!!tnDetails" @input="tnDetails = null" max-width="900")
    v-card(v-if="tnDetails")
      v-card-title.title.grey.lighten-3
        | Teilnehmer {{tnDetails.xid}}
        template(v-if="tnDetails.status == 'Storniert'")
          |  (Storniert)
        template(v-if="tnDetails.status == 'Reserviert'")
          |  (Reserviert bis {{datum(tnDetails.reserviertBis)}})
        v-spacer
        v-btn(@click="tnDetails = null" icon): v-icon mdi-close
      v-card-text.pt-3
        v-row(wrap)
          v-col(cols="12"): v-textarea(label="Preis" :rows="1" auto-grow :value="stripNewlines(tnDetails.termin.preisDetailsAll)" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Termin" :value="datumZeit(tnDetails.termin.zeit)" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Location" :value="tnDetails.termin.ort.name" disabled hide-details)
          v-col(cols="12" sm="2"): v-text-field(label="Tn.Nr." v-model="tnDetails.xid" disabled hide-details)
          v-col(cols="12" sm="5"): v-text-field(label="Vorname der_s Jugendlichen" v-model="tnDetails.vorname" disabled hide-details)
          v-col(cols="12" sm="5"): v-text-field(label="Nachname der_s Jugendlichen" v-model="tnDetails.nachname" disabled hide-details)
          v-col(cols="12"): v-text-field(label="Lautschrift" v-model="tnDetails.aussprache" persistent-hint hint="Wie wird der Name ausgesprochen? Besonderheiten?" disabled)
          v-col(cols="12" sm="6"): v-text-field(label="Gruppe" :value="tnDetails.gruppe && `${tnDetails.gruppe.xid}: ${tnDetails.gruppe.vollerName}` || ' '" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Schule" :value="tnDetails.schulklasse || '—'" disabled hide-details)
          v-col(cols="12" sm="6"): v-text-field(label="Geschlecht" v-model="tnDetails.geschlechtHuman" disabled)
          v-col(cols="12" sm="6"): input-date(label="Geburtstag" v-model="tnDetails.geburtstag" disabled)
          v-col(cols="12" sm="6"): v-text-field(label="Gfls. Behinderung" v-model="tnDetails.behinderung" disabled)
          v-col(cols="12" sm="6"): v-text-field(label="E-Mail-Adresse der_s Jugendlichen" v-model="tnDetails.email" disabled hint="(Diese E-Mail-Adresse nutzen wir ausschließlich, um Ihr Kind auf Angebote im Vorbereitungsprogramm direkt aufmerksam machen zu können.)" persistent-hint)
          v-col(cols="12"): v-checkbox.mt-0(label="Gegebenenfalls abweichende Adresse der_s Jugendliche_n" v-model="tnDetails.hatAdresse" disabled hide-details)
          template(v-if="tnDetails.hatAdresse")
            v-col(cols="12"): v-text-field(label="Adresszusatz" v-model="tnDetails.adresszusatz" disabled  hint="(z. B. c/o, abweichender Name am Briefkasten oder andere zur Zustellung notwendige Angabe)" persistent-hint)
            v-col(cols="12" sm="6"): v-text-field(label="Straße" v-model="tnDetails.strasse" disabled)
            v-col(cols="12" sm="6"): v-text-field(label="Hausnummer" v-model="tnDetails.hausnummer" disabled)
            v-col(cols="12" sm="6"): v-text-field(label="PLZ" v-model="tnDetails.plz" disabled)
            v-col(cols="12" sm="6"): v-text-field(label="Ort" v-model="tnDetails.ort" disabled)
          v-col(cols="12" v-if="tnDetails.termin.ort.juhu || tnDetails.werdeJuHuMitglied")
            v-checkbox(label="Juhu-Mitglied werden" v-model="tnDetails.werdeJuHuMitglied" disabled): div(slot="label")
              div(v-html="constants && constants.werdeJuhuHtml || 'werde JuHu (...Lade)'")
          v-col(cols="12" v-if="tnDetails.buchungen.length")
            .title.mb-2
              | Im &#32;
              a(:href="vpUrlFor(tnDetails).url") Vorbereitungsprogramm {{vpUrlFor(tnDetails).name}}
              |  angemeldet in<br>
            template(v-for="buchung in tnDetails.buchungen")
              .mb-1
                v-chip.mr-1(dark small :color="buchung.kurs.kategorie.color") {{buchung.kurs.kategorie.name}}
                v-chip(dark small :color="buchung.kurs.kategorie.color") {{buchung.kurs.kuerzel}}
                |  {{buchung.kurs.name}}{{buchung.warteliste ? " (auf Warteliste)" : ""}}

  v-dialog(:value="!!gruppeDetails" @input="gruppeDetails = null" max-width="900")
    div(v-if="gruppeDetails")
      v-card.mb-1
        v-card-title.title.grey.lighten-3
          | Gruppe {{gruppeDetails.xid}}
          v-spacer
          v-btn(@click="gruppeDetails = null" icon): v-icon mdi-close

      v-card.mb-1
        v-card-text
          v-row(wrap)
            v-col(cols="12" sm="2"): v-text-field(label="Nr." v-model="gruppeDetails.xid" disabled hide-details)
            v-col(cols="12" sm="10"): v-text-field(label="Name" v-model="gruppeDetails.vollerName" disabled hide-details)
            v-col(cols="12" sm="6" md="3"): v-text-field(label="Teilnehmer Angemeldet" :value="gruppeDetails.teilnehmer.filter((t) => t.status === 'Gebucht').length" disabled hide-details)
            v-col(cols="12" sm="6" md="3" :order="$vuetify.breakpoint.sm ? null : 1"): v-text-field(label="Plätze Storniert" :value="gruppeDetails.teilnehmer.filter((t) => t.status === 'Storniert').length" disabled hide-details)
            v-col(cols="12" sm="6" md="3"): v-text-field(label="Plätze Reserviert" :value="gruppeDetails.teilnehmer.filter((t) => t.status === 'Reserviert').length" disabled hide-details)
            v-col(cols="12" sm="6" md="3"): v-text-field(label="Reserviert Bis" :value="reserviertBis(gruppeDetails)" disabled hide-details)
            v-col(cols="12" order="2")
              strong Gruppenlink
              br
              a.text-body-1(:href="gruppeDetails.gruppenlink") {{gruppeDetails.gruppenlink}}

      v-card.mb-1
        v-card-title.title.grey.lighten-3 {{gruppeDetails.teilnehmer.filter((tn) => tn.status === 'Gebucht').length}} Teilnehmer

      template(v-for="tn in gruppeDetails.teilnehmer.filter((tn) => tn.status === 'Gebucht')")
        v-card.mb-1
          v-card-text
            v-row(wrap)
              v-col(cols="12" sm="2"): v-text-field(label="Tn.Nr." v-model="tn.xid" disabled hide-details)
              v-col(cols="12" sm="5"): v-text-field(label="Vorname" v-model="tn.vorname" disabled hide-details)
              v-col(cols="12" sm="5"): v-text-field(label="Nachname" v-model="tn.nachname" disabled hide-details)

</template>

<script>
import gql from 'gql-id.macro'
import graphqlErrorObject from '../backend/mixins/graphql-error-object.js'
import QueryFilter from '../backend/mixins/query-filter'
import stapelActions from '../backend/mixins/stapel-actions'
import AcceptDatenschutz from './accept-datenschutz'
import AcceptAgb from './accept-agb'
import AcceptFotos from './accept-fotos'
import AcceptNewsletter from './accept-newsletter'
import InputDate from './input-date'
import { orderBy, uniqBy } from 'lodash'
import moment from 'moment'

export default {
  name: 'jf-account',
  mixins: [graphqlErrorObject, stapelActions, QueryFilter],
  components: {
    AcceptDatenschutz,
    AcceptAgb,
    AcceptFotos,
    AcceptNewsletter,
    InputDate,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      constants: null,
      zeigeStornierte: false,
      tnDetails: null,
      gruppeDetails: null,
    }
  },
  apollo: {
    user: {
      fetchPolicy: "network-only",
      query: gql`query extraMyDetails {
        user: currentUser {
          id
          email
          nachname
          vorname
          telefon
          strasse
          strasseNr
          adresszusatz
          plz
          ort
          acceptAgbs
          acceptDatenschutz
          acceptPhotoVideo
          hatBekanntVon
          acceptInfoEmails
          sozialrabattBeantragt
          sozialrabattBestaetigt
          sozialausweisKommt
          komplett
          teilnehmer {
            id
            jahr
            jid
            xid
            komplett
            nachname
            vorname
            geschlechtHuman
            geburtstag
            behinderung
            email
            werdeJuHuMitglied
            adresszusatz
            strasse
            hausnummer
            plz
            ort
            sozialrabattBeantragt
            sozialrabattBewilligt
            stornoPreise
            preis
            preisDetailsAll
            gebucht
            status
            reserviertBis
            hatAdresse
            schulklasse
            gruppe {
              id
              xid
              vollerName
            }
            termin {
              id
              zeit
              fruehbucherDatum
              preisDetailsAll
              gestekartenbestellungAb
              gaestekartenbestellungAbString
              ort {
                id
                adverbial
                name
                juhu
                vpRegionen {
                  id
                  jahr
                  name
                  slug
                  bestellungAb
                  bestellungAbString
                }
              }
            }
            buchungen {
              id
              warteliste
              kurs {
                id
                name
                kuerzel
                kategorie {
                  id
                  name
                  color
                }
              }
            }
          }
          gaestekarten {
            id
            anzahlKarten
            anzahlPraesenzKarten
            storniert
            erwachsene
            kinder
            mitglieder
            mitgliederNamen
            streamer
            termin {
              id
            }
          }
          gruppen {
            id
            jid
            jahr
            xid
            userErinnertUm
            vollerName
            name
            gruppenlink
            schule {
              id
            }
            termin {
              id
              zeit
              fruehbucherDatum
              preisDetailsAll
              ort {
                id
                adverbial
                name
              }
            }
            teilnehmer {
              id
              xid
              vorname
              nachname
              status
              reserviertBis
            }
          }
          wartelisten {
            id
            status
            anzahlTeilnehmer
            voranmeldung
            termine {
              id
              zeit
              ort {
                id
                adverbial
                name
              }
            }
          }
        }
        constants {
          werdeJuhuHtml
          werdeJuhuDefault
          infoMail
          infoFax
          infoPost
        }
      }`,
      update(response) {
        this.constants = response.constants
        return response.user
      }
    },
  },
  computed: {
    edit() {
      return this.user
    },
    stornierteTn() {
      return this.user?.teilnehmer?.filter((tn) => tn.status == 'Storniert')?.length || 0
    },
    teilnehmer() {
      const status = this.zeigeStornierte ? 'Storniert' : 'Gebucht'
      const gaestekarten = this.user?.gaestekarten || []
      const today = moment().format("Y-MM-DD")
      return orderBy(this.user?.teilnehmer?.filter((tn) => tn.status == status)?.map((tn) => {
        const terminId = tn.termin.id
        const gk = gaestekarten.find((gk) => gk.termin.id == terminId)
        const anz = gk?.anzahlKarten || 0
        let total = `${anz} ${anz != 1 ? 'Plätze' : 'Platz'}`
        if (tn.termin.gestekartenbestellungAb) {
          if (today < tn.termin.gestekartenbestellungAb) {
            total = tn.termin.gaestekartenbestellungAbString || moment(tn.termin.gestekartenbestellungAb).format("[Bestellung ab] DD.MM.Y")
          }
        } else if (tn.termin.gaestekartenbestellungAbString) {
          total = tn.termin.gaestekartenbestellungAbString
        }
        let vorbereitungsprogramm = `${tn.buchungen.length} Kurs${tn.buchungen.length != 1 ? 'e' : ''} angemeldet`
        const region = tn.termin.ort.vpRegionen.find((r) => r.jahr === tn.jahr)
        if (region?.bestellungAb) {
          if (today < region.bestellungAb) {
            vorbereitungsprogramm = region.bestellungAbString || moment(region.bestellungAb).format("[Anmeldung ab] DD.MM.Y")
          }
        } else if (region?.bestellungAbString) {
          vorbereitungsprogramm = region.bestellungAbString
        }
        return { ...tn, total, vorbereitungsprogramm, gk }
      }) || [], 'id')
    },
    wartelisten() {
      const wls = this.user?.wartelisten?.filter(w => w.status === 'Neu') || []
      const va = wls.filter((w) => w.voranmeldung)
      const wl = wls.filter((w) => !w.voranmeldung)
      return [{ voranmeldung: true, liste: va }, {voranmeldung: false, liste: wl }].filter((w) => w.liste.length)
    },
    vpUrls() {
      return uniqBy(this.user?.teilnehmer?.filter(t => t.gebucht)?.map(this.vpUrlFor)?.filter(t => t.url) || [], 'url')
    }
  },
  methods: {
    reserviertBis(gruppe) {
      const reservierungen = orderBy(gruppe.teilnehmer.filter((tn) => tn.status == 'Reserviert'), 'reserviertBis')
      return this.datum(reservierungen[0]?.reserviertBis)
    },
    vpUrlFor(tn) {
      const region = tn.termin.ort.vpRegionen.find((r) => r.jahr === tn.jahr)
      if (region) {
        return { url: `/vorbereitungsprogramm/${region.slug}/${region.jahr}`, name: `${region.name} ${region.jahr}` }
      } else {
        return { url: null, name: null }
      }
    },
    stripNewlines(str) {
      return str?.replace(/\n/g, ' ')
    },
    strftime(date, format) {
      if (date) {
        return moment(date).format(format)
      } else {
        return ''
      }
    },
    datum(date) {
      return this.strftime(date, 'DD.MM.Y')
    },
    datumZeit(date) {
      return this.strftime(date, 'DD.MM.Y H:mm [Uhr]')
    },
    tage(termine) {
      return uniqBy(termine.map((termin) => (this.strftime(termin.zeit, 'DD.MM.Y')))).join(", ")
    },
  },
}
</script>
