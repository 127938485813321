<template lang="pug">
div(style="width: 100%")
  .grey.lighten-3.d-flex(v-if="title")
    v-menu(open-on-hover offset-y)
      template(v-slot:activator="{ on }")
        v-btn(v-on="on" slot="activator" icon)
          v-icon mdi-map-legend
      v-list
        template(v-for="preis in preiskategorien")
          v-list-item
            v-list-item-title {{preis.name}}
            v-list-item-icon(style="border-radius: 100%" :class="preis.color") &nbsp;

    v-spacer
    v-btn(icon @click="zoom = zoom / 1.3" :disabled="zoom < 0.1"): v-icon mdi-magnify-minus-outline
    v-btn(icon @click="zoom = zoom * 1.3" :disabled="zoom >= 3"): v-icon mdi-magnify-plus-outline
  v-card.saalplan(:style="`width: 100%; max-height: 70vh; font-size: ${zoom * value.scale}em`")
    .mx-auto.saalplan_scroller(:style="`width: ${value.width}em; height: ${value.height}em;`" :class="`${selecting ? 'selecting' : ''} ${!title ? 'saalplan_scroller_bg' : ''}`")
      template(v-for="text in texte")
        .saalplan_text(@click="$emit('text', text.oriText)" :style="text.style" :class="text.class") {{text.text}}
      template(v-for="verbindung in nachbarn")
        .verbindung(:style="verbindung.style")
      template(v-for="platz in plaetze")
        .saalplan_platz(@click="$emit('platz', platz.oriPlatz)" :style="platz.style" :class="platz.class")
          .saalplan_platz_text(v-if="platz.text") {{platz.text}}

</template>

<style lang="stylus" scoped>
.saalplan
  transition-duration: 0.3s;
  transition-property: font-size;
  overflow auto
  &_scroller_bg
    background-color #eee
    background-image linear-gradient(to right, rgba(0,0,0,0.02) 50%, transparent 50%, transparent), linear-gradient(to bottom, rgba(0,0,0,0.02) 50%, transparent 50%, transparent)
    background-size 2em 2em
    background-repeat repeat
  &_scroller
    z-index 0
    position relative
  &_text
    position absolute
    transform-origin top left
    white-space nowrap
    line-height 1
  &_platz
    z-index 3
    position absolute
    width 0.9em
    height 0.9em
    margin 0.05em
    border-radius 50%
    border 1px solid black
    &_text
      height 100%
      display flex
      justify-content center
      align-items center
      font-size 0.3em

    &::before
      position absolute
      top 0
      left 0
      display block
      width 100%
      height 100%
      border-radius 50%

  .incomplete::before
    content ""
    box-shadow 0 0 4px 0.075em #c02

  .collision::before
    content ""
    box-shadow 0 0 4px 0.075em purple

  .remembered::before
    content ""
    box-shadow 0 0 4px 0.075em darkorange

  .reserved:not(.reserved-me)
    background-repeat: repeat;
    background-position: center;
    background-size: 11px 11px;
    &::before
      content "×"
      line-height 0.85
      opacity 0.4
      overflow hidden
      text-align center

    .saalplan_platz_text
      display: none

  .selecting &_platz:hover:not(.reserved):not(.selected)
    box-shadow 0 0 5px 0.075em orange
    z-index 4

  .selected, .reserved-me
    box-shadow 0 0 0px 0.075em black
    z-index 4

  .reserved-me:after
    content ""
    display block
    top -3px
    left -3px
    position relative
    width 1em
    height 1em
    border-radius 100%
    border 2px solid black
    background-repeat repeat
    background-image linear-gradient(45deg, #ffffff44 16.67%, #ffffff00 16.67%, #ffffff00 50%, #ffffff44 50%, #ffffff44 66.67%, #ffffff00 66.67%, #ffffff00 100%)
    background-size 21.21px 21.21px

  .selected.reserved:not(.reserved-me):after
    content ""
    display block
    top -3px
    left -3px
    position relative
    width 1em
    height 1em
    border-radius 100%
    border 2px solid black
    background-repeat repeat
    background-image linear-gradient(45deg, #f00 16.67%, #ffffff00 16.67%, #ffffff00 50%, #f00 50%, #f00 66.67%, #ffffff00 66.67%, #ffffff00 100%)
    background-size 21.21px 21.21px


  .selected.reserved
    box-shadow 0 0 0px 0.075em black
    z-index 4

  .verbindung
    z-index 1
    position absolute
    background-color lightgrey
    transform-origin left


</style>

<script>
export default {
  name: 'saalplan-show',
  props: {
    value: Object,
    show: String,
    editing: Boolean,
    selecting: Boolean,
    title: Boolean,
  },
  data() {
    return {
      zoom: 1
    }
  },
  computed: {
    zugaengeById() {
      const zugaenge = {}
      this.value.zugaenge.forEach((r) => {
        zugaenge[r.id] = r
      })
      return zugaenge
    },
    bloeckeById() {
      const bloecke = {}
      this.value.bloecke.forEach((r) => {
        bloecke[r.id] = r
      })
      return bloecke
    },
    preiskategorien() {
      return this.value.preiskategorien || []
    },
    preiskategorienById() {
      const preiskategorien = {}
      this.value.preiskategorien.forEach((r) => {
        preiskategorien[r.id] = r
      })
      return preiskategorien
    },
    texte() {
      return this.value.texte.map((text) => {
        let style = `top: ${text.y}em; left: ${text.x}em; transform: scale(${text.scale}) rotate(${text.rotation}deg);`
        if (text.bold) {
          style += " font-weight: bold;"
        }
        let clas = text.color.split(" ").map((c) => (/-\d$/.test(c) ? `text--${c}` : `${c}--text`)).join(" ")
        if (text.selected) {
          clas += ' selected'
        }
        return { text: text.text || "…", style, class: clas, oriText: text }
      })
    },
    plaetze() {
      const show = this.show
      const editing = this.editing
      const radius = 0.5
      const zugaengeById = this.zugaengeById
      const bloeckeById = this.bloeckeById
      const preiskategorienById = this.preiskategorienById
      return this.value.plaetze.map((platz) => {
        let text = null
        const preiskategorie = preiskategorienById[platz.preiskategorie]
        let color = null
        if (show === 'best') {
          text = platz.id
        } else if (show === 'platz') {
          text = `${platz.platznummer || ''}`
        } else if (show === 'reihe') {
          text = `${platz.reihe || ''}`
        } else if (show === 'reihe_platz') {
          text = `${platz.reihe || ''} ${platz.platznummer || ''}`
        } else if (show === 'zugang') {
          const zugang = zugaengeById[platz.zugang]
          text = `${zugang && zugang.name || ''}`
          color = zugang && zugang.color
        } else if (show === 'block') {
          const block = bloeckeById[platz.block]
          text = `${block && block.name || ''}`
          color = block && block.color
        } else if (show === 'preiskategorie') {
          text = `${preiskategorie && preiskategorie.name || ''}`
          color = preiskategorie && preiskategorie.color
        }
        let clas = platz.selected ? 'selected' : ''
        if (editing) {
          if (this.value.plaetze.some(p => (
            p !== platz && (platz.x - radius < p.x && platz.x + radius > p.x) && (platz.y - radius < p.y && platz.y + radius > p.y)
          ))) {
            clas += ' collision'
          }
          if (platz.remembered) {
            clas += ' remembered'
          }
          if (!platz.platznummer || !platz.reihe || !platz.block || !platz.zugang || !platz.stuhlart || !platz.preiskategorie) {
            clas += ' incomplete'
          }
        }
        if (platz.reserved) {
          clas += ' reserved'
        }
        if (platz.reservedMe) {
          clas += ' reserved-me'
        }
        if (color) {
          clas += ` ${color}`
        } else if (preiskategorie) {
          clas += ` ${preiskategorie.color}`
        } else {
          clas += " grey"
        }
        return {
          ...platz,
          oriPlatz: platz,
          style: `top: ${platz.y}em; left: ${platz.x}em;`,
          class: clas,
          text,
        }
      })
    },
    nachbarn() {
      const width = 0.7
      const plaetze = this.value.plaetze
      return plaetze.map((platz) => {
        if (!platz.nachbar) {
          return
        }
        const nachbar = plaetze.find((p) => p.id == platz.nachbar)
        if (!nachbar) {
          return
        }

        const left1 = platz.x
        const left2 = nachbar.x
        const top1 = platz.y
        const top2 = nachbar.y

        let top = top1
        let left = left1
        if (left1 > left2) {
          top = top2
          left = left2
        }

        const diagonal = Math.sqrt((left2 - left1) * (left2 - left1) + (top2 - top1) * (top2 - top1))
        const winkel = Math.atan((top2 - top1) / (left2 - left1))

        let selected = ''
        if (platz.selected && this.editing) {
          selected = 'box-shadow: 0 0 0 2px red; z-index: 2;'
        }
        const style = `${selected}height: ${width}em; width: ${diagonal}em; top: ${top}em; left: ${left}em; transform: translate(0.5em, ${(1-width)/2}em) rotate(${winkel}rad);`

        return { platz, nachbar, style }
      }).filter((p) => p)
    }
  },
}
</script>
