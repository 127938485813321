import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

import App from '../../frontend/layouts/frontend.vue'

const scrollBehavior = function(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    const scrollTo = to.params.scrollTo
    if (scrollTo === false) {
      return
    }
    return scrollTo || { x: 0, y: 0 }
  }
}

const routes = [
  {
    path: "*",
    name: "app",
    props: (route) => ({ path: route.path }),
    component: App,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior,
  routes,
  fallback: false
})

export default router
