<template lang="pug">
div
  v-text-field(:clearable="clearable" :disabled="disabled" @click:clear="clear" @click:append="menuOpen = !menuOpen" append-icon="mdi-calendar" :label="label" :placeholder="placeholder" :value="showDate" @change="textInput" :hide-details="hideDetails" :error-messages="errorArray")
  v-menu(lazy :close-on-content-click="false" v-model="menuOpen" transition="scale-transition" offset-y full-width :nudge-right="40" max-width="290px" min-width="290px")
    template(v-slot:activator="{ on }")
      .not-activator(v-on="on")
    v-date-picker(:value="pickerDate" @input="pickerInput" locale="de-DE" first-day-of-week="1" scrollable autosave)

</template>

<style lang="stylus">
.not-activator
  width 100%
  height 0
</style>

<script>
import moment from 'moment'

export default {
  name: 'input-date',
  props: {
    value: true,
    label: String,
    placeholder: String,
    clearable: Boolean,
    hideDetails: Boolean,
    disabled: Boolean,
    errorMessages: {
      type: Array,
      default() { return [] }
    }
  },
  data() {
    return {
      error: null,
      menuOpen: false,
      forceUpdate: 0
    }
  },
  computed: {
    moment() {
      return moment(this.value, "YYYY-MM-DD")
    },
    showDate() {
      const force = this.forceUpdate % 2 === 0 ? '' : ' '
      if (this.moment.isValid()) {
        return this.moment.format('DD.MM.YYYY') + force
      } else {
        return force
      }
    },
    pickerDate() {
      if (this.moment.isValid()) {
        return this.moment.format('YYYY-MM-DD')
      } else {
        return null
      }
    },
    errorArray() {
      const arr = []
      if (this.error || (this.value && !this.moment.isValid())) {
        arr.push('ungültig TT.MM.JJJJ')
      }
      return arr.concat(this.errorMessages)
    }
  },
  watch: {
    value() {
      this.forceUpdate++
      this.error = null
    }
  },
  methods: {
    pickerInput(value) {
      this.menuOpen = false
      let date = moment(value, "YYYY-MM-DD")
      if (date.isValid()) {
        date.hour(this.moment.hour())
        date.minute(this.moment.minute())
        this.$emit('input', date.format('YYYY-MM-DD'))
      }
      this.forceUpdate++
    },
    textInput(value) {
      window.m = moment
      if (value) {
        const date = moment(value, "DD.MM.YYYY")
        if (date.isValid()) {
          if (date.year() < 1000) {
            date.year(date.year() + 2000)
          }
          this.$emit('input', date.format('YYYY-MM-DD'))
          this.forceUpdate++
        } else {
          this.error = true
        }
      } else {
        this.$emit('input', null)
        this.forceUpdate++
      }
    },
    clear() {
      this.$emit('input', null)
      this.forceUpdate++
    }
  }
}
</script>
