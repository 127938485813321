<template lang="pug">

div(v-if="isMitarbeiter || validQrScannerKey")
  template(v-if="!termin")
    h1.pa-4 Ticket Scanner
    template(v-if="!ort")
      v-simple-table
        tbody
          tr(v-for="oOrt in orte" :key="oOrt.id")
            td {{oOrt.name}}
            td.text-right: v-btn(@click="queryFilterUpdate('ort', oOrt.id, null, { hash: keyWithHash })" icon color="primary" outlined): v-icon mdi-qrcode

    template(v-else)
      .pa-4
        v-btn.float-right(icon @click="queryFilterUpdates({ort: null, termin: null}, null, { hash: keyWithHash })"): v-icon mdi-close
        | {{ort.name}}
      v-simple-table
        tbody
          tr(v-for="tTermin in termine.filter(t => t.ort.id == ort.id)" :key="tTermin.id")
            td {{datum(tTermin.zeit)}}
            td {{zeit(tTermin.zeit)}}
            td.text-right: v-btn(@click="queryFilterUpdate('termin', tTermin.id, null, { hash: keyWithHash }); camera = 'auto'" icon color="primary" outlined): v-icon mdi-qrcode

  template(v-else)
    .px-4.primary.white--text.text-center
      v-btn.float-right(icon small color="white" @click="queryFilterUpdate('termin', null, null, { hash: keyWithHash })" style="margin-bottom: -0.5em;"): v-icon mdi-close
      | {{datum(termin.zeit)}} {{zeit(termin.zeit)}} {{termin.ort.adverbial}} {{termin.ort.name}}
    div(style="position: relative" ref="scanner")
      qrcode-stream(:track="(outline && paintOutline) || undefined" @decode="!outline && detected($event)")
      .text-center.title.pa-2(:style="`position: absolute; top:0; left: 0; width: 100%; background: rgba(${showMsg},0.85`" v-if="showMsg")
        | {{msg}}
      v-btn(icon @click="toggleFullscreen" absolute bottom right): v-icon mdi-fullscreen
      v-btn(@click="outline = !outline" absolute bottom style="right: 50px" icon): v-icon {{outline ? 'mdi-invert-colors' : 'mdi-invert-colors-off' }}

div(v-else-if="!$apollo.queries.validQrScannerKey.loading")
  .pa-4.title.text-center Nicht Authentifiziert

</template>

<script>
import gql from 'gql-id.macro'
import graphqlErrorObject from '../backend/mixins/graphql-error-object.js'
import QueryFilter from '../backend/mixins/query-filter'
import { QrcodeStream } from 'vue-qrcode-reader'
import moment from 'moment'
import { uniqBy, sortBy } from 'lodash'

let lastQr = null
let lastScan = {}
let scans = {}
let timeOut = null
let paused = false
let paueTimeout = null
let delayed = []
let nr = 0

export default {
  mixins: [graphqlErrorObject, QueryFilter],
  components: {
    QrcodeStream
  },
  props: {
    value: Object,
  },
  data() {
    return {
      msg: "",
      showMsg: "",
      outline: true,
    }
  },
  apollo: {
    validQrScannerKey:{
      query: gql `query jfTicketScannerVue(
        $key: String!
      ) {
        validQrScannerKey(key: $key)
      }`,
      variables() {
        return { key: this.key || '' }
      },
      skip() {
        return !this.key
      }
    },
    termine: {
      query: gql`query jfTicketScannerVue {
        termine(q: "{\"sorts\":[\"zeit\",\"ort_name\"],\"sichtbare\":true,\"kommende_und_heute\":true}", role: ANON) {
          id
          zeit
          ort {
            id
            name
            adverbial
          }
        }
      }`,
    }
  },
  watch: {
    termin() {
      scans = {}
      nr = 0
      this.clearResults()
    },
  },
  computed: {
    isMitarbeiter() {
      return this.$store.getters.isMitarbeiter
    },
    orte() {
      return sortBy(uniqBy(this.termine?.map(t => t.ort), 'id'), 'name')
    },
    ort() {
      const ortId = this.$route.query.ort
      return this.orte?.find(o => o.id == ortId)
    },
    termin() {
      const terminId = this.$route.query.termin
      return this.termine?.find(t => t.id == terminId)
    },
    keyWithHash() {
      return location.hash
    },
    key() {
      return location.hash.replace(/^#/, '')
    },
  },
  methods: {
    toggleFullscreen() {
      if (document.fullscreenElement) {
        this.exitFullscreen()
      } else {
        this.requestFullscreen()
      }
    },
    requestFullscreen() {
      const elem = this.$refs.scanner

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    },
    color(valid) {
      if (valid == "yes") {
        return "0, 255, 0"
      } else if (valid == "maybe") {
        return "255, 180, 0"
      } else if (valid == "no") {
        return "255, 0, 0"
      } else { // valid == "pending"
        return ""
      }
    },
    paint(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints
        const scan = scans[detectedCode.rawValue] || {}

        ctx.strokeStyle = "red"
        ctx.fillStyle = `rgba(${this.color(scan.valid) || "100, 100, 100"}, 0.5)`

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
      }
    },
    detected(qr) {
      let scan = scans[qr]
      if (!scan) {
        scan = { qr, valid: "pending" }
        scans[scan.qr] = scan
        this.validateQrCode(scan)
      } else if (scan.valid !== "pending") {
        this.showResult(scan)
      }
    },
    pauseEnd() {
      const scan = delayed.pop()
      paused = false
      if (scan) {
        this.showResult(scan)
      }
      if (delayed.length) {
        paused = true
      }
    },
    showResult(scan) {
      if (paused) {
        delayed.push(scan)
      } else {
        if (lastScan.valid == "yes") {
          lastScan.valid = 'maybe'
          lastScan.msg = "Doppelt"
        }
        lastScan = scan
        if (scan.valid === "yes") {
          paused = true
          clearTimeout(paueTimeout)
          paueTimeout = setTimeout(this.pauseEnd, 1500)
        }
        this.msg = `${scan.nr}: ${scan.msg}`
        this.showMsg = this.color(scan.valid)
      }
      clearTimeout(timeOut)
      timeOut = setTimeout(this.clearResults, 3000)
    },
    clearResults()  {
      lastQr = null
      this.showMsg = ''
      this.msg = ''
    },
    paintOutline(detectedCodes, ctx) {
      const detectedCode = detectedCodes.find((c) => c.rawValue === lastQr) || detectedCodes[0]
      if (detectedCode) {
        clearTimeout(timeOut)
        timeOut = setTimeout(this.clearResults, 3000)
        if (lastQr !== detectedCode.rawValue) {
          lastQr = detectedCode.rawValue
          this.detected(detectedCode.rawValue)
        }
        this.paint([detectedCode], ctx)
      }
    },
    strftime(date, format) {
      if (date) {
        return moment(date).format(format)
      } else {
        return ''
      }
    },
    datum(date) {
      return this.strftime(date, 'DD.MM.Y')
    },
    zeit(date) {
      return this.strftime(date, 'H:mm [Uhr]')
    },
    validateQrCode(scan) {
      const variables = {
        qr: scan.qr,
        key: this.key,
        terminId: this.termin?.id
      }
      this.gqlAction({
        mutationName: 'checkQr',
        variables,
        then: async (response) => {
          const result = response?.data?.checkQrCode
          if (result) {
            scan.valid = result.valid
            scan.msg = result.msg
            scan.nr = ++nr
            this.showResult(scan)
          }
        },
        mutation: gql`
          mutation checkQrCode(
            $qr: String!
            $key: String
            $terminId: ID!
          ) {
            checkQrCode(
              qr: $qr
              key: $key
              terminId: $terminId
            ) {
              valid
              msg
            }
          }`,
      })
    },
  },
}
</script>
