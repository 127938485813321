<script>
import JfAccountDetails from './jf-account-details.vue'
import JfLogin from './jf-login.vue'
import JfGruppencode from './jf-gruppencode.vue'
import JfTerminAnmeldung from './jf-termin-anmeldung.vue'
import JfTicketScanner from './jf-ticket-scanner.vue'
import JfVorbereitungsprogramm from './jf-vorbereitungsprogramm.vue'
import JfGaestekarten from './jf-gaestekarten.vue'
import store from '../frontend/store'

export default {
  functional: true,
  render(h, context) {
    let el = null
    const value = context.props.value
    const key = `pagepart-${value.id}`
    if (value.kind === 'HTML') {
      el = h('div', { domProps:{ innerHTML: value.html }, key })
    } else if (value.kind === 'Bild') {
      el = h('v-img', { attrs: { src: value.bild, aspectRatio: value.aspectRatio }, key })
    } else if (value.kind === 'Container') {
      // v-container(:fluid="value.fluid")
      //   div x
    } else if (value.kind === 'FeierTerminAnmeldung') {
      el = h(JfTerminAnmeldung, { attrs: { value }, key })
    } else if (value.kind === 'Gruppencode') {
      el = h(JfGruppencode, { attrs: { value }, key })
    } else if (value.kind === 'Login') {
      el = h(JfLogin, { attrs: { value }, key })
    } else if (value.kind === 'AccountDetails') {
      el = h(JfAccountDetails, { attrs: { value }, key })
    } else if (value.kind === 'Vorbereitungsprogramm') {
      el = h(JfVorbereitungsprogramm, { attrs: { value }, key })
    } else if (value.kind === 'TicketScanner') {
      el = h(JfTicketScanner, { attrs: { value }, key })
    } else if (value.kind === 'Gästekarten') {
      el = h(JfGaestekarten, { attrs: { value }, key })
    } else if (location.hostname === 'localhost') {
      el = h('pre', { key }, JSON.stringify(value, null, 2))
    }

    if (value.around == 'Card') {
      el = h('v-card', [h('v-card-text', [el])])
    } else if (value.around == 'Abstand') {
      el = h('div', { class: "ma-4" }, [el])
    }

    if (store.getters.editPage) {
      const editBtn = h('v-btn', { attrs: { icon: true }, on: { click() { context.listeners?.editPart(value.id) } } }, [h('v-icon', 'mdi-pencil')])
      el = [el, editBtn]
    }

    return el
  }
}
</script>
