<template lang="pug">
  v-container.jf-vorbereitungsprogramm(flex grid-list-xl ref="top")
    template(v-if="isMitarbeiter")
      v-select(label="Jahr" :value="urlJahr" :items="vpJahre" @input="openPage(null, $event)" v-if="vpJahre.length > 1")
      template(v-for="region in vpRegionen" v-if="region.jahr == urlJahr")
        v-btn(@click="openPage(region.slug, null)" :input-value="urlRegion === region.slug") {{region.name}}
      hr.mt-3.mb-5

    v-layout(row wrap v-if="$apollo.queries.vpRegion.loading || $apollo.queries.vpRegionen.loading || $apollo.queries.user.loading")
      v-flex.text-center(xs12): v-progress-linear(indeterminate)

    template(v-else-if="!showRegion")
      template(v-if="vpTeilneher.length")
        template(v-for="(teilnehmers, url) in vpUrls")
          v-card.mb-3: v-card-text.title.grey.lighten-3
            router-link(:to="url") Vorbereitungsprogramm {{teilnehmers[0].vpName}}
          template(v-for="teilnehmer in teilnehmers")
            v-card.mb-3
              v-card-title.title.grey.lighten-3 {{teilnehmer.vollerName}}
              v-card-text.pt-4
                template(v-if="teilnehmer.buchungen.length")
                  template(v-for="buchung in teilnehmer.buchungen")
                    .mb-1
                      v-chip.mr-1(dark small :color="buchung.kurs.kategorie.color") {{buchung.kurs.kategorie.name}}
                      v-chip(dark small :color="buchung.kurs.kategorie.color") {{buchung.kurs.kuerzel}}
                      |  {{buchung.kurs.name}}{{buchung.warteliste ? " (auf Warteliste)" : ""}}
                template(v-else) Keine Kurse gebucht.
      template(v-else-if="loggedIn")
        .my-3.title Kein passendes Vorbereitungsprogramm gefunden, haben sie Teilnehmer angemeldet?
      template(v-else)
        .my-3.title Kein Vorbereitungsprogramm gefunden, ist die URL richtig?

    v-layout(row wrap v-else-if="showRegion.beendet")
      v-flex(xs12 v-html="showRegion.beendetText")

    v-layout(row wrap v-else-if="alleKurse.length === 0")
      v-flex(xs12): v-card: v-card-text.title Vorbereitungsprogramm {{showRegion.name}} {{showRegion.jahr}}
      v-flex(xs12) Keine Kurse vorhanden.

    v-layout(row wrap v-else-if="!hatPassendenTn && nurMitPassendemTn")
      v-flex(xs12): v-card: v-card-text.title Vorbereitungsprogramm {{showRegion.name}} {{showRegion.jahr}}
      v-flex(xs12) Keine passenden Kurse vorhanden

    v-layout(row wrap v-else)
      v-flex(xs12).mb-4(v-html="showRegion.erklaerungstext")
      v-flex(xs12).mb-4(v-if="anmeldungAb") {{anmeldungAb}}
      v-flex(xs12): radio-group(label="Sortierung" row :items="orders" :value="queryFilterPagination.sortBy" @change="queryFilterPaginationUpdate({ sortBy: $event })")
      v-flex(xs12 sm4 lg2 v-if="alleMonate.length > 1"): v-select(label="Monat" v-model="monate" :items="alleMonate" multiple chips small-chips deletable-chips)
      v-flex(xs12 sm4 lg2 v-if="wochentage.length > 1"): v-select(label="Wochentag" v-model="tage" :items="wochentage" multiple chips small-chips deletable-chips)
      v-flex(xs12 sm4 lg2 v-if="kategorien.length > 1"): v-select(label="Kategorie" v-model="kategorie" :items="kategorien" multiple chips small-chips deletable-chips item-text="name" item-value="id")
      v-flex(xs12 sm4 lg2 v-if="themenbereiche.length > 1"): v-select(label="Themenbereich" v-model="themenbereich" :items="themenbereiche" multiple chips small-chips deletable-chips item-text="name" item-value="id")
      v-flex(xs12 sm4 lg2 v-if="frequenzen.length > 1"): v-select(label="Frequenz" v-model="frequenz" :items="frequenzen" multiple chips small-chips deletable-chips)
      v-flex(xs12 sm4 lg2 v-if="regionBezirke.length > 1"): v-select(label="Ort/Bezirk" v-model="regionBezirk" :items="regionBezirke" multiple chips small-chips deletable-chips)

    template(v-if="!(!hatPassendenTn && nurMitPassendemTn)" v-for="katKurs in kursKategorien")
      v-layout(row wrap)
        v-flex(xs12): v-chip.mt-5.title(dark :color="katKurs.kategorie.color") {{katKurs.kategorie.name}}
        template(v-for="kurs in katKurs.kurse")
          v-flex(xs12 sm6 md4 xl3 :key="kurs.id")
            v-hover(close-delay="0")
              v-card(slot-scope="{ hover }" :class="kurs.highlight ? hover ? 'elevation-10 highlight' : 'elevation-8 highlight' : hover ? 'elevation-4' : ''" :id="`kursId-${kurs.id}`")
                v-img(@click="queryFilterUpdate('kurs', kurs.id, false)" :src="kurs.bild ? kurs.bild : require('../../assets/images/jugendfeier.svg')" :aspect-ratio="4/3" contain)
                v-card-text(style="position: relative")
                  v-btn(@click="queryFilterUpdate('kurs', kurs.id, false)" :color="kurs.kategorie.color" dark fab absolute style="top: -38px" right)
                    template(v-if="hover || angemeldetIn[kurs.id]")
                      v-icon {{ angemeldetIn[kurs.id] ? 'mdi-heart' : 'mdi-heart-outline' }}
                    template(v-else)
                      | {{kurs.kuerzel}}
                  | {{kurs.name}}
                v-progress-linear.ma-0(:color="progressColor(kurs.auslastung)" :value="kurs.auslastung")

    v-dialog(:value="!!showKurs" @input="queryFilterUpdate('kurs', null, false)" max-width="700px")
     v-container.pa-0(flex grid-list-md)
      v-card(v-if="showKurs")
        v-card-title.grey.lighten-3(style="position: relative")
          v-img.shrink(v-if="$vuetify.breakpoint.smAndUp" :src="showKurs.bild ? showKurs.bild : require('../../assets/images/jugendfeier.svg')" :aspect-ratio="4/3" contain width="100px")
          .pl-3
            v-chip.mt-0(dark small :color="showKurs.kategorie.color") {{showKurs.kategorie.name}}
            | &#32;
            v-chip.mt-0(dark small :color="showKurs.kategorie.color") {{showKurs.kuerzel}}
            | &#32;
            v-chip(v-if="showKurs.highlight" dark small color="primary") Highlight
            .headline.mt-2 {{showKurs.name}}
          v-btn(icon absolute right style="top: 12px; z-index: 3" @click="queryFilterUpdate('kurs', null, false)"): v-icon mdi-close
        v-card-text
          template(v-if="showKurs.themenbereich")
            strong Themenbereich:
            |  {{showKurs.themenbereich}}<br>
          template(v-if="showKurs.dauerStr")
            strong Zeit:
            |  {{showKurs.dauerStr}}<br>
          template(v-if="showKurs.frequenz")
            strong Frequenz:
            |  {{showKurs.frequenz}}
            template(v-if="showKurs.termine")
              |  ({{showKurs.termine}} Termine)
            <br>
          template(v-if="showKurs.regionBezirk")
            strong Ort/Bezirk:
            |  {{showKurs.regionBezirk}}<br>
          template(v-if="showKurs.ort")
            strong Adresse:
            |  {{showKurs.ort}}<br>
          template(v-if="showKurs.kosten")
            strong Kosten:
            |  {{showKurs.kosten}}<br>
          br
          | {{showKurs.beschreibung}}
          br
          template(v-if="!loggedIn")
            v-btn(@click="goToLogin(showKurs.id)") Einloggen
          template(v-else)
            br
            v-alert.mb-3(:value="gqlHasErrorsFor('vpBuchungTn')" dismissible @input="gqlClearErrorsFor('vpBuchungTn')")
              .subheading Anmeldung fehlgeschlagen
              template(v-for="error in gqlErrorsFor('vpBuchungTn', 'base')")
                template(v-if="/in dieser Kategorie angemeldet/.test(error)")
                  | {{error.replace(/in dieser Kategorie angemeldet.*/, 'in')}}
                  v-chip(dark small :color="showKurs.kategorie.color") {{showKurs.kategorie.name}}
                  | {{error.replace(/.*in dieser Kategorie angemeldet/, 'angemeldet')}}
                  br
                template(v-else) {{error}}<br>
            v-alert.mb-3(:value="gqlHasErrorsFor('vpBuchungDestroyTn')" dismissible @input="gqlClearErrorsFor('vpBuchungDestroyTn')")
              .subheading Stornieren fehlgeschlagen
              template(v-for="error in gqlErrorsFor('vpBuchungDestroyTn', 'base')") {{error}}<br>
            v-alert.mb-3(:value="!!successMsg" dismissible type="success" @input="successMsg = null")
              | {{successMsg}}
            p(v-if="angemeldetIn[showKurs.id] && angemeldetIn[showKurs.id].length")
              | Angemeldet:<br>
              template(v-for="tn in angemeldetIn[showKurs.id]")
                v-icon(small) mdi-heart
                |  {{tn.vollerName}} {{tn.warteliste ? '(auf Warteliste)' : ''}}
                template(v-if="showKurs.maxPlaetzeProBuchung > 1")  ({{tn.buchung.plaetze}} {{tn.buchung.plaetze == 1 ? 'Platz' : 'Plätze'}})
                v-btn(v-if="showKurs.anmeldungOffen" @click="storniere(tn.buchung, tn, showKurs)") Stornieren
                <br>
            template(v-if="!showKurs.anmeldungOffen")
              template(v-if="showKurs.abgesagt")
                | Nicht mehr verfügbar!
              template(v-else-if="showKurs.anmeldungTel && showKurs.plaetzeFreiDisplay > 0")
                | Es gibt noch Plätze anfragen telefonisch unter {{constants && constants.infoPhone}}.
              template(v-else)
                | Die Anmeldung ist {{showKurs.anmeldungBegonnen ? 'bereits' : 'noch'}} geschlossen
            template(v-else-if="anmeldbareTn.length < 1")
              | Es ist kein Teilnehemer angemeldet der sich hier anmelden kann.
            template(v-else)
              v-layout(wrap)
                v-flex(xs12 :sm9="showKurs.maxPlaetzeProBuchung > 1"): v-select(label="Anmeldung für Teilnehmer" v-model="teilnehmerId" :items="anmeldbareTn" item-text="vollerName" item-value="id")
                v-flex(xs12 sm3 v-if="showKurs.maxPlaetzeProBuchung > 1"): v-text-field(label="Platz-Anzahl" v-model="plaetze" :error-messages="gqlErrorsFor('vpBuchungTn', 'plaetze')")

              v-btn(@click="anmelden(showKurs.id, false)" :loading="loading > 0" :disabled="(showKurs.plaetzeFreiDisplay <= 0) || !!(angemeldetIn[showKurs.id] && angemeldetIn[showKurs.id].find((tn) => tn.id == teilnehmerId))" color="primary")
                | Anmelden
              template(v-if="!showKurs.plaetzeFreiDisplayMehr")
                template(v-if="showKurs.plaetzeFreiDisplay <= 0")
                  | Keine Plätze mehr frei
                template(v-else-if="showKurs.plaetzeFreiDisplay == 1")
                  | nur noch 1 Platz
                template(v-else)
                  | nur noch {{showKurs.plaetzeFreiDisplay}} Plätze

              template(v-if="showKurs.wartelisteAktiv && showKurs.plaetzeFreiDisplay < 2")
                br
                v-btn(@click="anmelden(showKurs.id, true)" :loading="loadingWarteliste > 0" :disabled="showKurs.wartelistePlaetzeFreiDisplay <= 0" color="primary")
                  | Warteliste
                template(v-if="!showKurs.wartelistePlaetzeFreiDisplayMehr")
                  template(v-if="showKurs.wartelistePlaetzeFreiDisplay <= 0")
                    | Keine Plätze mehr frei
                  template(v-else-if="showKurs.wartelistePlaetzeFreiDisplay == 1")
                    | nur noch 1 Platz
                  template(v-else)
                    | nur noch {{showKurs.wartelistePlaetzeFreiDisplay}} Plätze

</template>

<style lang="scss" scoped>
.highlight {
  &.elevation-8  {
    box-shadow: 0 5px 5px -3px #ec7404, 0 8px 10px 1px #ec740480, 0 3px 14px 2px #ec740480 !important
  }
  &.elevation-10  {
    box-shadow: 0 6px 6px -3px #ec7404, 0 10px 14px 1px #ec740480, 0 4px 18px 3px #ec740480 !important;
  }
}
</style>

<script>
import graphqlErrorObject from '../backend/mixins/graphql-error-object.js'
import QueryFilter from '../backend/mixins/query-filter.js'
import gql from 'gql-id.macro'
import moment from 'moment'
import { uniq, flatMap, sortBy, groupBy } from 'lodash'

export default {
  props: {
    data: Object
  },
  mixins: [graphqlErrorObject, QueryFilter],
  data() {
    return {
      vpRegionen: [],
      vpRegion: [],
      tage: [],
      regionBezirk: [],
      themenbereich: [],
      frequenz: [],
      monate: [],
      kategorie: [],
      successMsg: null,
      queryDefaultOrder: 'kuerzel',
      queryDefaultDescending: false,
      teilnehmerId: null,
      plaetze: '1',
      loadingWarteliste: 0,
      loading: 0,
      storniereLoading: 0,
    }
  },
  apollo: {
    vpRegionen: {
      query: gql`query ExtraVorbereitungsprogrammVueRegionen {
        vpRegionen(q: "{\"sorts\":[\"zeit\",\"ort_name\"],\"sichtbare\":true,\"kommende\":true}", role: ANON) {
          id
          jahr
          name
          slug
        }
      }`
    },
    vpJahr: {
      query: gql`query ExtraVorbereitungsprogrammVueRegionen {
          jahre(where: { isVpDefault: true}, role: ANON) {
            id
          }
      }`,
      update(response) {
        return response && response.jahre && response.jahre[0] && response.jahre[0].id || null
      }
    },
    vpRegion: {
      query() {
        if (this.loggedIn) {
          return gql`query ExtraVorbereitungsprogrammVueRegionUser($id: ID) {
            vpRegion: vpRegionen(where: { id: $id }, role: USER) {
              id
              jahr
              name
              beendet
              beendetText
              erklaerungstext
              bestellungAb
              bestellungAbString
              orte {
                id
              }
              kategorien {
                id
                name
                slug
                color
                erlaubeMehrfachanmeldung
                limitierteKurse
                kurse {
                  id
                  highlight
                  beschreibung
                  kuerzel
                  name
                  frequenz
                  termine
                  bild
                  anfang
                  ende
                  dauerStr
                  woechentlichLetzterTermin
                  kosten
                  themenbereich
                  ort
                  regionBezirk
                  auslastung
                  plaetzeFreiDisplay
                  plaetzeFreiDisplayMehr
                  wartelisteAuslastung
                  wartelistePlaetzeFreiDisplay
                  wartelistePlaetzeFreiDisplayMehr
                  wartelisteAktiv
                  anmeldungBegonnen
                  anmeldungOffen
                  anmeldungTel
                  abgesagt
                  maxPlaetzeProBuchung
                }
              }
            }
          }`
        } else {
          return gql`query ExtraVorbereitungsprogrammVueRegion($id: ID) {
            vpRegion: vpRegionen(where: { id: $id }, role: ANON) {
              id
              beendet
              beendetText
              erklaerungstext
              bestellungAb
              bestellungAbString
              kategorien {
                id
                name
                slug
                color
                kurse {
                  id
                  highlight
                  themenbereich
                  beschreibung
                  kuerzel
                  name
                  frequenz
                  bild
                }
              }
            }
          }`
        }
      },
      variables() {
        return {
          id: this.regionId
        }
      },
      skip() {
        return !this.regionId
      }
    },
    user: {
      query: gql`query ExtraVorbereitungsprogrammVueUser {
        user: currentUser {
          id
          roles
          teilnehmer {
            id
            jahr
            vollerName: name
            vorname
            nachname
            status
            gebucht
            termin {
              id
              ort {
                id
                adverbial
                name
                vpRegionen {
                  id
                  jahr
                  name
                  slug
                }
              }
            }
            buchungen {
              id
              kursId
              warteliste
              plaetze
              kurs {
                id
                name
                kuerzel
                highlight
                kategorie {
                  id
                  name
                  color
                }
              }
            }
            termin {
              id
              ortId
            }
          }
        }
      }`
    },
    constants: {
      query: gql`query extraVorbereitungsprogrammVueConstants {
        constants {
          infoPhone
        }
      }`
    },
  },
  watch: {
    anmeldbareTn(tns) {
      if (tns && tns.length > 0) {
        this.teilnehmerId = tns[0].id
      } else {
        this.teilnehmerId = null
      }
    },
    '$route.query.kurs'() {
      this.gqlClearAllErrors()
      this.successMsg = null
      this.plaetze = '1'
    }
  },
  computed: {
    urlPath() {
      return this.$route.path.split('/') || []
    },
    urlRegion() {
      return this.urlPath[2] || null
    },
    urlJahr() {
      return +this.urlPath[3] || this.vpJahr
    },
    angemeldetIn() {
      const angemeldetIn = {}
      const user = this.user
      if (user && user.teilnehmer && user.teilnehmer.length) {
        const tns = user.teilnehmer
        this.alleKurse.forEach((kurs) => {
          tns.forEach((teilnehmer) => {
            let tnBuchung = teilnehmer.buchungen.find((b) => b.kursId == kurs.id)
            if (tnBuchung) {
              angemeldetIn[kurs.id] = angemeldetIn[kurs.id] || []
              angemeldetIn[kurs.id].push({ ...teilnehmer, warteliste: tnBuchung.warteliste, buchung: tnBuchung })
            }
          })
        })
      }
      return angemeldetIn
    },
    anmeldbareTn() {
      const user = this.user
      const region = this.showRegion
      const jahr = region && region.jahr
      if (user && region) {
        const orte = region.orte
        return user.teilnehmer.filter((tn) => tn.status == 'Gebucht' && tn.jahr == jahr && orte.some((o) => o.id == tn.termin.ortId))
      } else {
        return []
      }
    },
    hatPassendenTn() {
      return !!this.anmeldbareTn.length
    },
    nurMitPassendemTn() {
      return this.loggedIn && !this.isMitarbeiter
    },
    loggedIn() {
      return this.user && this.user.id
    },
    isMitarbeiter() {
      const user = this.user
      return user && /mitarbeiter|manager|admin/.test(user.roles.join(','))
    },
    orders() {
      const orders = [
        { text: 'Name', value: 'name' },
        { text: 'Kürzel', value: 'kuerzel' },
      ]
      if (this.loggedIn) {
        orders.push({ text: 'Datum', value: 'anfang' })
      }
      return orders
    },
    wochentagNummern() {
      const tage = {}
      moment.weekdays().forEach((name, index) => {
        tage[name] = index
      })
      return tage
    },
    monatsNummern() {
      const monate = {}
      moment.months().forEach((name, index) => {
        monate[name] = index
      })
      return monate
    },
    wochentage() {
      return sortBy(uniq(this.alleKurse.map((k) => k.wochentag)), (t) => this.wochentagNummern[t] || t)
    },
    alleMonate() {
      return sortBy(uniq(flatMap(this.alleKurse, (k) => k.monate)), (t) => this.monatsNummern[t] || t)
    },
    vpJahre() {
      return uniq(this.vpRegionen.map((r) => r.jahr)).sort()
    },
    regionId() {
      const region = this.urlRegion
      const jahr = this.urlJahr
      const vpRegion = this.vpRegionen.find((r) => r.jahr == jahr && r.slug === region)
      return vpRegion && vpRegion.id
    },
    showRegion() {
      return this.vpRegion[0]
    },
    anmeldungAb() {
      const ab = this.showRegion?.bestellungAb
      if (ab && moment(ab).isAfter()) {
        return this.showRegion.bestellungAbString || moment(ab).format("[Anmeldung ab] DD.MM.Y")
      } else {
        return null
      }
    },
    kategorien() {
      const region = this.vpRegion[0] || {}
      return region.id == this.regionId && region.kategorien || []
    },
    themenbereiche() {
      return uniq(this.alleKurse.map((k) => k.themenbereich)).filter((r) => r).sort()
    },
    regionBezirke() {
      return uniq(this.alleKurse.map((k) => k.regionBezirk)).filter((r) => r).sort()
    },
    frequenzen() {
      return uniq(this.alleKurse.map((k) => k.frequenz)).sort()
    },
    alleKurse() {
      return sortBy(flatMap(this.kategorien, (kat) => kat.kurse.map((kurs) => {
        let anfang = moment(kurs.anfang)
        let ende = moment(kurs.woechentlichLetzterTermin || kurs.ende || kurs.anfang)
        if (anfang.isAfter(ende)) {
          const tmp = anfang
          anfang = ende
          ende = tmp
        }
        const monate = []

        let current = moment(anfang)
        do {
          monate.push(current.format('MMMM'))
          current = current.add(1, 'month')
        } while (current.isBefore(ende))

        return {
          ...kurs,
          kategorie: kat,
          wochentag: moment(kurs.woechentlichLetzterTermin || anfang).format('dddd'),
          monate,
        }
      })), this.queryFilterPagination.sortBy)
    },
    kurse() {
      let kurse = this.alleKurse

      const kategorie = this.kategorie
      if (kategorie.length) {
        kurse = kurse.filter((k) => kategorie.indexOf(k.kategorie.id) > -1)
      }

      const regionBezirk = this.regionBezirk
      if (regionBezirk.length) {
        kurse = kurse.filter((k) => regionBezirk.indexOf(k.regionBezirk) > -1)
      }

      const themenbereich = this.themenbereich
      if (themenbereich.length) {
        kurse = kurse.filter((k) => themenbereich.indexOf(k.themenbereich) > -1)
      }

      const tage = this.tage
      if (tage.length) {
        kurse = kurse.filter((k) => tage.indexOf(k.wochentag) > -1)
      }

      const monate = this.monate
      if (monate.length) {
        kurse = kurse.filter((k) => k.monate.some((monat) => monate.indexOf(monat) > -1))
      }

      const freq = this.frequenz
      if (freq.length) {
        kurse = kurse.filter((k) => freq.indexOf(k.frequenz) > -1)
      }

      return kurse
    },
    kursKategorien() {
      const kategorien = {}
      const katKurse = {}
      this.kurse.forEach((kurs) => {
        if (!katKurse[kurs.kategorie.id]) {
          katKurse[kurs.kategorie.id] = []
          kategorien[kurs.kategorie.id] = kurs.kategorie
        }
        katKurse[kurs.kategorie.id].push(kurs)
      })
      const kats = Object.entries(katKurse).map(([kategorieId, kurse]) => {
        return { kategorie: kategorien[kategorieId], kurse }
      })
      return sortBy(kats, 'kategorie.name')
    },
    showKurs() {
      const id = this.$route.query.kurs
      if (id) {
        return this.alleKurse.find((k) => k.id === id)
      }
    },
    vpTeilneher() {
      return this.user?.teilnehmer?.filter(t => t.gebucht)?.map((tn) => ({ ...tn, ...this.vpUrlFor(tn)})) || []
    },
    vpUrls() {
      return groupBy(this.vpTeilneher, 'url')
    }
  },
  methods: {
    vpUrlFor(tn) {
      const region = tn.termin.ort.vpRegionen.find((r) => r.jahr === tn.jahr)
      if (region) {
        return { url: `/vorbereitungsprogramm/${region.slug}/${region.jahr}`, vpName: `${region.name} ${region.jahr}` }
      } else {
        return { url: null, vpName: null }
      }
    },
    openPage(region, jahr) {
      this.$router.push(`/vorbereitungsprogramm/${region || this.urlRegion}/${jahr || this.urlJahr}`)
    },
    goToLogin(kursId) {
      window.location = `/admin/login?return-to=vorbereitungsprogramm-${this.urlRegion}-${this.vpJahr == this.urlJahr ? '' : this.urlJahr}-${kursId}`
    },
    progressColor(progress) {
      if (progress >= 100) {
        return 'red'
      } else if (progress > 70) {
        return 'orange'
      } else if (progress > 0 || progress === 0) {
        return 'green'
      } else {
        return 'grey lighten-2'
      }
    },
    dateFormat(date) {
      if (date) {
        return moment(date).format('DD. MMMM YYYY')
      }
    },
    anmelden(kursId, warteliste) {
      if (warteliste) {
        this.loadingWarteliste++
      } else {
        this.loading++
      }
      this.gqlClearErrorsFor('vpBuchungTn')
      this.successMsg = null
      this.$apollo.mutate({
        mutation: gql`mutation ExtraVorbereitungsprogrammVueAnmelden(
          $kursId: ID!
          $ticketId: ID
          $warteliste: Boolean
          $plaetze: String
        ) {
          vpBuchungTn(
            kursId: $kursId
            ticketId: $ticketId
            warteliste: $warteliste
            plaetze: $plaetze
          ) {
            id
          }
        }`,
        variables: {
          kursId,
          warteliste,
          ticketId: this.teilnehmerId,
          plaetze: this.plaetze,
        }
      }).then(data => {
        if (warteliste) {
          this.loadingWarteliste--
        } else {
          this.loading--
        }
        this.successMsg = `${warteliste ? 'Wartelisteneintrag' : 'Anmeldung'} erfolgreich.`
        this.$apollo.queries.user.refetch()
        this.$emit('input', null)
      }, err => {
        this.gqlAddErrors(err)
        if (warteliste) {
          this.loadingWarteliste--
        } else {
          this.loading--
        }
      })
    },
    storniere(buchung, tn, kurs) {
      if (confirm(`${tn.vollerName} von ${kurs.name} abmelden?`)) {
        const variables = { id: buchung.id }
        this.gqlAction({
          loading: 'storniereLoading',
          mutationName: 'vpBuchungDestroyTn',
          variables,
          mutation: gql`
          mutation VorbereitungsprogrammRegionVueVpAnmeldungBestaetigungMail($id: ID!) {
            vpBuchungDestroyTn(id: $id) {
              success
            }
          }`,
        }).then(
          (response) => {
            this.successMsg = `Storno erfolgreich.`
            this.$apollo.queries.user.refetch()
            this.$emit('input', null)
          },
          (err) => {
            this.gqlAddSingleError('Ein Fehler ist aufgetreten', 'vpBuchungDestroyTn', 'hidden_')
          },
        )
      }
    }
  }
}
</script>
