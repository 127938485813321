import axios from 'axios'
import Qs from 'qs'

const paramsSerializer = function paramSerializer(params) {
  return Qs.stringify(params, { arrayFormat: 'brackets' })
}

axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.xsrfCookieName = 'authenticity_token'

const api = axios.create({
  baseURL: `/api/v1b/`,
  paramsSerializer
})

const admin = axios.create({
  baseURL: `/admin/`,
  paramsSerializer
})

admin.interceptors.response.use(null, function(error) {
  if (error && error.response && error.response.status === 401) {
    if (window && window.location) {
      window.location = '/admin/login'
    }
  }
  return Promise.reject(error)
})

const delayPromise = function(delay, val) {
  return new Promise(function(resolve) {
    setTimeout(function() { resolve(val) }, delay)
  })
}

const loadingPromise = function loadingPromise(that, loadingProp, timeMin, promise) {
  if (!promise) {
    promise = timeMin
    timeMin = 0
  }
  that[loadingProp] = true
  const setDone = function setDone(promises) {
    that[loadingProp] = false
    return promises[0]
  }
  const setDoneErr = function setDone(err) {
    that[loadingProp] = false
    return Promise.reject(err)
  }
  return Promise.all([promise, delayPromise(timeMin)]).then(setDone, setDoneErr)
}

const loadingPromiseCount = function loadingPromise(that, loadingProp, timeMin, promise) {
  if (!promise) {
    promise = timeMin
    timeMin = 0
  }
  that[loadingProp] = that[loadingProp] - (-1)
  const setDone = function setDone(promises) {
    that[loadingProp] = that[loadingProp] - 1
    return promises[0]
  }
  const setDoneErr = function setDone(err) {
    that[loadingProp] = that[loadingProp] - 1
    return Promise.reject(err)
  }
  return Promise.all([promise, delayPromise(timeMin)]).then(setDone, setDoneErr)
}

const findWithSpaces = function findWithSpaces(obj, search, text) {
  const lowerText = text.toLowerCase()
  if (search) {
    return (search || '').toLowerCase().split(' ').every(part => !part || lowerText.indexOf(part) > -1)
  } else {
    return true
  }
}

export { api, admin, loadingPromise, loadingPromiseCount, delayPromise, findWithSpaces }
