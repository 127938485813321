<template lang="pug">
  v-app
    cookie-consent
    .main-grid
      .main-grid--left.primary
        .main-grid--spacer
          div(v-if="$store.getters.isAdmin" style="position: absolute; top: 0; left: 0; z-index: 1;" :class="$vuetify.breakpoint.mdAndDown ? 'd-flex flex-column' : ''")
            v-btn(icon dark :x-small="$vuetify.breakpoint.mdAndDown" v-if="!editPage" @click="$store.commit('TOGGLE_EDIT_PAGE')"): v-icon mdi-pencil-box-outline
            template(v-else)
              v-btn(icon dark :x-small="$vuetify.breakpoint.mdAndDown" @click="$store.commit('TOGGLE_EDIT_PAGE')"): v-icon mdi-pencil-box
              v-btn(icon dark :x-small="$vuetify.breakpoint.mdAndDown" @click="editMenu = 'new'"): v-icon mdi-pencil-plus
              v-menu(bottom offset-y open-on-hover close-delay="300" v-if="orphanMenus.length")
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon dark :x-small="$vuetify.breakpoint.mdAndDown" v-bind="attrs" v-on="on"): v-icon mdi-dots-vertical
                v-list
                  template(v-for="menu in orphanMenus")
                    v-list-item(@click.stop.prevent="activateMenu(menu)" :href="menuUrl(menu)")
                      v-list-item-icon(v-if="!!menu.icon1"): v-icon mdi-{{menu.icon1}}
                      v-list-item-content: v-list-item-title {{menu.name}}
                      v-list-item-icon(v-if="!!menu.icon2"): v-icon mdi-{{menu.icon2}}
                      v-list-item-icon: v-btn(icon small @click.stop.prevent="editMenu = menu.id"): v-icon mdi-pencil

          jf-menu(:value="topMenu" :editPage="editPage" :small="!$vuetify.breakpoint.mdAndUp" :list="!$vuetify.breakpoint.mdAndDown" @edit="editMenu = $event")

      .main-grid--right.d-none.d-lg-block.primary
        .main-grid--spacer
          jf-menu(:value="topMenu2" :editPage="editPage" list right @edit="editMenu = $event")

      v-main.main-grid--center
        router-view

        //- v-alert.mx-3.mt-3(v-for="msg, msgN in flash" :key="msgN" dismissible elevation="5" :type="msg.type" style="pointer-events: auto;" @input="flash.splice(msgN, 1)") {{msg.text}}
        div(style="position: fixed; top: 0; left: 0; z-index: 1000; width: 100%; height: 100%; pointer-events: none; display: grid; align-items: center;")
          .main-grid--center
            v-alert.mx-3.mt-3(v-for="msg, msgN in $store.state.messages" :key="msg.id" dismissible elevation="5" :type="msg.type" style="pointer-events: auto;" @input="$store.commit('removeMsg', msg.id)") {{msg.text}}

        v-progress-linear(v-if="$apollo.queries.pagePartsData.loading" indeterminate color="primary")
        jf-page-part(v-else v-for="part in pageParts" :key="part.id" :value="part" @editPart="editPagePart=$event" @addPart="addPart($event)")
        v-btn(icon @click="editPagePart = 'new'" v-if="editPage"): v-icon mdi-pencil-plus

        component(:is="jfEditMenuComponent" v-model="editMenu" v-if="editMenu")
        component(:is="jfEditPagePartComponent" v-model="editPagePart" v-if="editPagePart")

    v-spacer
    v-footer.menu-footer.justify-center.text-center.grey.lighten-3.py-4.elevation-1(v-if="footer.length"): div
      jf-menu(:value="footer" footer :editPage="editPage" @edit="editMenu = $event")
    portal-target(name="page-bottom")
</template>

<style lang="sass" scoped>
@use "sass:math";

.side-logo {
  max-width: 100%;
  width: 130px;
  @media (min-width: 1264px) {
    width: 250px;
  }
}
.main-grid {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1264px) {
    flex-wrap: nowrap;
  }
  &--logo {
    order: 10;
    @media (min-width: 1264px) {
      order: 0;
    }
  }
  &--spacer {
    display: flex;
    @media (min-width: 1264px) {
      padding: calc(10% - 16px) 12px;
      margin-right: auto;
      margin-left: auto;
      max-width: 250px;
      flex-direction: column;
      height: 100%;
    }
  }
  $center-width-lg: 900px;
  $center-width-xl: 1185px;
  &--left {
    flex-grow: 1;
    width: 100%;
  }
  &--right {
    @media (min-width: 1264px) {
      order: 3;
    }
  }
  &--left, &--right {
    @media (min-width: 1264px) {
      height: round(math.div($center-width-lg, 3) * 1);
      min-width: 0;
      width: 50%;
    }
    @media (min-width: 1904px) {
      height: round(math.div($center-width-xl, 3) * 1);
    }
  }
  &--center {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    @media (min-width: 960px) {
      width: $center-width-lg;
    }
    @media (min-width: 1264px) {
      ::v-deep .v-main__wrap::before {
        content: "";
        display: block;
        width: $center-width-lg;
        @media (min-width: 1904px) {
          width: $center-width-xl;
        }
      }
    }
    @media (min-width: 1904px) {
      width: $center-width-xl;
    }
  }
}
.main-container {
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  max-width: none;
}
.menu-footer::v-deep p:last-child {
  margin-bottom: 0;
}
</style>

<script>
import KeepQuery from "../../backend/mixins/keep-query"
import menuByName, { pageParts, menuOptions, menuRecompute, activateMenu, menuUrl, currentMenu } from "../store/menu"
import jfMenu from "../../frontend2/jf-menu.vue"
import gql from "gql-id.macro"

let metaKeywordsEl = null
let metaDescriptionEl = null
let titleEl = null
let siteTitle = ''
const siteTitleSeparator = " — "
function findOrCreateMeta(metas, name) {
  let el = metas.find((m) => m.name == name)
  if (!el) {
    const head = document.head
    const meta = metas[1] || metas[0] || head.firstChild
    el = document.createElement('meta')
    el.name = name
    head.insertBefore(el, meta)
  }
  return el
}
function findOrCreateTitle(metas) {
  let el = document.getElementsByTagName('title')[0]
  if (!el) {
    const head = document.head
    const meta = metas[1] || metas[0] || head.firstChild
    el = document.createElement('title')
    head.insertBefore(el, meta)
  }
  return el
}

export default {
  props: {
    path: String,
  },
  mixins: [KeepQuery],
  components: {
    jfMenu
  },
  data() {
    return {
      preloaded: true,
      componentLoaded: false,
      jfEditMenuComponent: null,
      jfEditPagePartComponent: null,
      editMenu: null,
      editPagePart: null,
      pagePartsData: [],
    }
  },
  beforeMount() {
    menuOptions.$http = this.$http
    menuOptions.$apollo = this.$apollo
    menuOptions.$router = this.$router
    menuRecompute(this.$vuetify.breakpoint.name)
    ;(window.dataVueApp?.flash || []).forEach((msg) => {
      if (msg.text !== true) {
        msg.timeout = 1000 * 10
        this.$store.commit('addSuccess', msg)
      }
    })
  },
  apollo: {
    pagePartsData: {
      query: gql`query FrontendVue($host: String, $path: String) {
        pagePartsData: menuItems(host: $host, path: $path, role: ANON) { id pageTitle metaKeywords metaDescription pageParts { id kind name position html bild aspectRatio fluid around } }
      }`,
      variables() {
        return {
          path: this.path,
          host: location.host,
        }
      },
      skip() {
        return this.preloaded
      },
      fetchPolicy: "network-only",
    }
  },
  watch: {
    path() {
      if (this.preloaded) {
        this.preloaded = false
      }
      this.$store.commit("clearMsgs")
    },
    pagePartsData(menus) {
      const menu = menus[0] || {}
      currentMenu.id = menu.id
      if (!titleEl) {
        const metas = Object.values(document.getElementsByTagName('meta'))
        metaKeywordsEl = findOrCreateMeta(metas, 'keywords')
        metaDescriptionEl = findOrCreateMeta(metas, 'description')
        titleEl = findOrCreateTitle()
        siteTitle = titleEl.textContent.replace(new RegExp(`.*${siteTitleSeparator}`), '')
      }
      titleEl.textContent = menu.pageTitle ? `${menu.pageTitle}${siteTitleSeparator}${siteTitle}` : siteTitle
      metaKeywordsEl.content = menu.metaKeywords?.join(", ") || ''
      metaDescriptionEl.content = menu.metaDescription || ''
    },
    "$vuetify.breakpoint.name"(name) {
      menuRecompute(name)
    },
    editPage: {
      immediate: true,
      async handler(edit) {
        if (!this.componentLoaded && edit) {
          this.componentLoaded = true
          const components = await Promise.all([import('../../frontend2/jf-edit-menu.vue'), import('../../frontend2/jf-edit-page-part.vue')])
          this.jfEditMenuComponent = components[0].default
          this.jfEditPagePartComponent = components[1].default
        }
      }
    },
  },
  computed: {
    editPage() {
      return this.$store.getters.editPage
    },
    topMenu() {
      const topMenu = menuByName.TopMenu?.children || []
      if (this.$vuetify.breakpoint.mdAndDown) {
        const topMenu2 = menuByName.TopMenu2?.children || []
        return [].concat(topMenu, topMenu2)
      } else {
        return topMenu
      }
    },
    topMenu2() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return []
      } else {
        return menuByName.TopMenu2?.children || []
      }
    },
    footer() {
      return menuByName.Footer?.children || []
    },
    orphanMenus() {
      return menuByName.orphanMenus?.children || []
    },
    pageParts() {
      return this.menu.pageParts || []
    },
    menu() {
      if (this.preloaded) {
        return { pageParts: pageParts.parts || [] }
      } else {
        const pagePartsData = this.pagePartsData
        return pagePartsData && pagePartsData[0] || {}
      }
    }
  },
  methods: {
    menuUrl,
    activateMenu,
    only1Icon(menu) {
      return !!(!menu.name && ((menu.icon1 && !menu.icon2) || (!menu.icon1 && menu.icon2)))
    },
    splitSpacers(menus) {
      let current = []
      const all = [current]
      menus.forEach((menu) => {
        if (menu.kind == 'Spacer') {
          current = []
          current.spacer = menu
          all.push(current)
        } else {
          current.push(menu)
        }
      })
      return all
    },
    addPart(where) {
      if (where.after) {
        this.editPagePart = 'new'
      }
    }
  }
}
</script>
