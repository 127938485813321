<template lang="pug">
#cookie-consent.cookie-consent(v-if="showNotice" :class="$vuetify.breakpoint.mdAndUp ? 'cookie-footer' : ''")
  .cookie-wrapper.pa-3.px-lg-12.elevation-3.primary.white--text.accent--links(ref="notice")
    .text-justify.no-p-margin-last.mb-1(v-html="text")
    .text-center
      v-btn.accent(@click="save('enabled')") {{ja}}
      | &#32;
      v-btn(@click="save('disabled')") {{nein}}

  portal(to="page-bottom" v-if="$vuetify.breakpoint.mdAndUp")
    .cookie-consent(:style="`height: ${height}px`")

</template>

<style lang="scss">
#cookie-consent {
    z-index: 9001;
}
#cookie-consent.cookie-footer {
  .cookie-wrapper {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }
}
</style>

<script>
export default {
  name: 'jf-cookie-consent',
  data() {
    const data = window.dataVueApp || {}
    return {
      height: 0,
      text: data.cookieText,
      ja: data.cookieJa,
      nein: data.cookieNein,
    }
  },
  computed: {
    showNotice() {
      return this.$store.state.gtagId && (this.$store.state.gtagEnabled === 'ask' || this.$route.hash === '#cookie')
    },
  },
  watch: {
    showNotice: {
      immediate: true,
      handler() {
        this.computeHeight()
        this.$nextTick(() => { this.computeHeight() })
      },
    }
  },
  methods: {
    save(enable) {
      if (this.$route.hash === '#cookie') {
        this.$router.replace({ hash: '' })
      }
      this.$store.dispatch('SET_GTAG', enable)
    },
    computeHeight() {
      const height = this.$refs.notice?.getBoundingClientRect()?.height
      this.height = height || 0
    }
  }
}
</script>
