<script>
import { activateMenu, menuUrl } from "../frontend/store/menu"
import store from "../frontend/store"

function only1Icon(menu) {
  return !!(!menu.name && ((menu.icon1 && !menu.icon2) || (!menu.icon1 && menu.icon2)))
}

function listText(h, ctx, menu, depth) {
  const { props, parent } = ctx
  const text = []
  if (!menu.name && !menu.bildM && menu.icon1 && menu.icon2) {
    text.push(h('v-list-item-icon', {}, [
      h('v-icon', { }, `mdi-${menu.icon1}`),
      h('v-icon', { }, `mdi-${menu.icon2}`),
    ]))
  } else {
    if (menu.icon1) {
      text.push(h('v-list-item-icon', {}, [
        h('v-icon', {}, `mdi-${menu.icon1}`)
      ]))
    }
    if (menu.bildM) {
      text.push(
        h('v-list-item-title', [
          h('img', { attrs: {
            src: ctx.props.small ? menu.bildS : menu.bildM,
            alt: menu.name,
            style: "max-width: 100%; display: block",
            width: depth > 0 ? undefined : "250px",
            height: depth > 0 ? ctx.props.small ? 56 : 64 : undefined,
          } } ),
        ])
      )
    } else if (menu.name) {
      if (menu.kind == 'Logout') {
        const name = `${store.state.user.vorname || ''} ${store.state.user.nachname || ''}`.replace(/^ | $/g, '') || store.state.user.email || ''
        text.push(h('v-list-item-content', [
          h('v-list-item-title', menu.name),
          h('v-list-item-subtitle', [name])
        ]))
      } else {
        text.push(h('v-list-item-title', menu.name))
      }
    }
    if (menu.icon2) {
      text.push(h('v-list-item-icon', {}, [
        h('v-icon', { }, `mdi-${menu.icon2}`)
      ]))
    }
  }
  if (props.editPage) {
    text.push(h('v-btn', { attrs: { style: "pointer-events: auto;", small: true, icon: true }, on: { click(event) {
      event.preventDefault()
      event.stopPropagation()
      parent.editMenu = menu.id
    } } }, [
      h('v-icon', 'mdi-pencil')
    ]))
  }
  return text
}

function btnText(h, ctx, menu) {
  const { props, parent } = ctx
  const text = []
  if (menu.icon1) {
    text.push(h('v-icon', {}, `mdi-${menu.icon1}`))
  }
  if (menu.bildM) {
    text.push(h('img', { attrs: { src: ctx.props.small ? menu.bildS : menu.bildM, alt: menu.name, style: `max-height: ${ctx.props.small ? 56 : 64}px` } } ))
  } else if (menu.html) {
    text.push(h('div', { domProps:{ innerHTML: menu.html } }))
  } else if (menu.name) {
    text.push(menu.name)
  }
  if (menu.icon2) {
    text.push(h('v-icon', { }, `mdi-${menu.icon2}`))
  }
  if (props.editPage) {
    text.push(h('v-btn', { attrs: { style: "pointer-events: auto;", small: true, icon: true }, on: { click(event) {
      event.preventDefault()
      event.stopPropagation()
      parent.editMenu = menu.id
    } } }, [
      h('v-icon', 'mdi-pencil')
    ]))
  }
  return text
}

function nav(h, ctx, listItems, depth) {
  const { props, parent } = ctx
  const dark = depth == 0
  const color = depth == 0 ? "transparent" : undefined
  const nav = depth == 0
  const all = []
  let items = []
  listItems.forEach((item) => {
    if (item.kind == "Spacer") {
      if (ctx.right === false || !ctx.props.list) {
        ctx.right = true
      }
      if (items.length) {
        all.push(h('v-toolbar-items', {}, items))
      }
      all.push(h("v-spacer"))
      if (props.editPage) {
        all.push(h('v-btn', { attrs: { style: "pointer-events: auto;", small: true, icon: true }, on: { click(event) {
          event.preventDefault()
          event.stopPropagation()
          parent.editMenu = item.id
        } } }, [
          h('v-icon', 'mdi-pencil')
        ]))
      }
      items = []
    } else if (item.kind == "Seite" || item.kind == "Link" || item.kind == "Logout") {
      items.push(h("v-btn",
        {
          props: { text: true, href: menuUrl(item), icon: only1Icon(item)}, class: item.bildM ? 'px-0' : '',
          on: { click(event) {
            activateMenu(item, event)
          }}
        },
        btnText(h, ctx, item)
      ))
    } else if (item.kind == "Text") {
      items.push(h("v-btn", { props: { text: true, disabled: true, icon: only1Icon(item) }, class: item.bildM ? 'px-0' : '' },
        btnText(h, ctx, item)
      ))
    } else if (item.kind == "Menu") {
      items.push(menu(h, ctx, item, depth))
    } else {
      console.warn("unknown kind", item.kind)
    }
  })
  if (items.length) {
    all.push(h("v-toolbar-items", {}, items))
  }
  if (all.length) {
    return h("v-toolbar", { attrs: { nav, dark, color } }, all)
  } else {
    return null
  }
}

function footer(h, ctx, listItems, depth) {
  const { props, parent } = ctx
  const all = []
  let items = []
  let prev = null
  listItems.forEach((item) => {
    if (item.kind == "Spacer") {
      if (ctx.right === false || !ctx.props.list) {
        ctx.right = true
      }
      if (items.length) {
        all.push(items)
      }
      all.push(h("br"))
      if (props.editPage) {
        all.push(h('v-btn', { attrs: { style: "pointer-events: auto;", small: true, icon: true }, on: { click(event) {
          event.preventDefault()
          event.stopPropagation()
          parent.editMenu = item.id
        } } }, [
          h('v-icon', 'mdi-pencil')
        ]))
      }
      items = []
    } else if (item.kind == "Seite" || item.kind == "Link" || item.kind == "Logout") {
      if (['Seite', 'Link', 'Logout', 'Text'].indexOf(prev) > -1) {
        items.push(h("span", { class: "primary--text px-2" }, " | "))
      }
      items.push(h("a",
        {
          attrs: { text: true, href: menuUrl(item), icon: only1Icon(item)}, class: item.bildM ? 'px-0' : '',
          on: { click(event) {
            activateMenu(item, event)
          }}
        },
        btnText(h, ctx, item)
      ))
    } else if (item.kind == "Text") {
      if (['Seite', 'Link', 'Logout', 'Text'].indexOf(prev) > -1) {
        items.push(h("span", { class: "primary--text px-2" }, " | "))
      }
      items.push(h("span", { props: { text: true, disabled: true, icon: only1Icon(item) }, class: item.bildM ? 'px-0' : '' },
        btnText(h, ctx, item)
      ))
    } else if (item.kind == "Menu") {
      items.push(menu(h, ctx, item, depth))
    } else {
      console.warn("unknown kind", item.kind)
    }
    prev = item.kind
  })
  if (items.length) {
    all.push(items)
  }
  return all
}

function list(h, ctx, listItems, depth) {
  const { props, parent } = ctx
  const dark = depth == 0
  const color = depth == 0 ? "transparent" : undefined
  const nav = depth == 0
  const all = []
  let items = []
  listItems.forEach((item) => {
    if (item.kind == "Spacer") {
      if (ctx.right === false || !ctx.props.list) {
        ctx.right = true
      }
      if (items.length) {
        all.push(h('v-list', { attrs: { nav, dark, color } }, items))
      }
      all.push(h("v-spacer"))
      if (props.editPage) {
        all.push(h('v-btn', { attrs: { dark, style: "pointer-events: auto;", small: true, icon: true }, on: { click(event) {
          event.preventDefault()
          event.stopPropagation()
          parent.editMenu = item.id
        } } }, [
          h('v-icon', 'mdi-pencil')
        ]))
      }
      items = []
    } else if (item.kind == "Seite" || item.kind == "Link" || item.kind == "Logout") {
      items.push(h("v-list-item",
        {
          props: { href: menuUrl(item) },
          class: item.bildM ? 'px-0' : '',
          on: { click(event) {
            activateMenu(item, event)
          } }
        },
        listText(h, ctx, item, depth)
      ))
    } else if (item.kind == "Text") {
      items.push(h("v-list-item", { class: item.bildM ? 'px-0' : '' },
        listText(h, ctx, item, depth)
      ))
    } else if (item.kind == "Menu") {
      items.push(menu(h, ctx, item, depth))
    } else {
      console.warn("unknown kind", item.kind)
    }
  })
  if (items.length) {
    all.push(h('v-list', { attrs: { nav, dark, color } }, items))
  }
  return all
}

function menu(h, ctx, item, depth) {
  if (item.children.length || ctx.props.editPage) {
    return [h('v-menu',
      {
        props: {
          openOnHover: true,
          closeDelay: 300,
          offsetX: ctx.props.list,
          offsetY: !ctx.props.list,
          left: ctx.right,
          top: ctx.props.footer,
          bottom: !ctx.props.footer,
        },
        scopedSlots: {
          activator: props => {
            if (ctx.props.list) {
              return h('v-list-item', { ...props, class: item.bildM ? 'px-0' : '' }, listText(h, ctx, item, depth))
            } else {
              return h('v-btn', { ...props, props: { text: true, icon: only1Icon(item) }, class: item.bildM ? 'px-0' : '' }, btnText(h, ctx, item))
            }
          },
          default: () => list(h, ctx, item.children, depth + 1),
        },
      },
    )]
  } else {
    return null
  }
}

export default {
  name: "jf-menu",
  props: {
    value: Array,
    editPage: Boolean,
    list: Boolean,
    small: Boolean,
    footer: Boolean,
    right: {
      type: Boolean,
      default: null,
    },
  },
  functional: true,
  render(h, context) {
    const ctx = { ...context, right: context.props.right }
    if (ctx.props.list) {
      return list(h, ctx, ctx.props.value, 0)
    } else if (ctx.props.footer) {
      return footer(h, ctx, ctx.props.value, 0)
    } else {
      return nav(h, ctx, ctx.props.value, 0)
    }
  },
}
</script>
