<template lang="pug">
v-container.extra-guest-ticket-booking(flex grid-list-md ref="top")
  template(v-if="$apollo.queries.user.loading")
    v-progress-linear(:active="true" indeterminate)
  //- template(v-else)
    h1 Gästekarten
    //- template(v-if="gaestekarten.length === 0")
      p Sie haben noch keine Teilnehmer Gebucht und können daher keine Gästekarten bestellen
    //- template(v-for="gaestekarte, gkI in gaestekarten")
      v-card.mb-3
        v-card-title.title.grey.lighten-3 {{strftime(gaestekarte.termin.zeit, "dddd D. MMM Y H:mm [Uhr]")}} {{gaestekarte.termin.ort.adverbial}} {{gaestekarte.termin.ort.name}}
        v-card-text.pt-4
          v-row
            v-col(cols="12" :md="hasStreamer(gaestekarte) ? 3 : 4"): v-text-field(v-model="gaestekarte.erwachsene" label="Anzahl Erwachsene" persistent-hint :hint="`Eintrittskarte je ${gaestekarte.termin.ticketpreis.gast} €`" :disabled="true")
            v-col(cols="12" :md="hasStreamer(gaestekarte) ? 3 : 4"): v-text-field(v-model="gaestekarte.kinder" label="Anzahl Kinder" persistent-hint :hint="`Eintrittskarte je ${gaestekarte.termin.ticketpreis.gastKind} €  (bis einschließlich 13 Jahre)`" :disabled="true")
            v-col(cols="12" v-if="hasStreamer(gaestekarte)" md="3"): v-text-field(v-model="gaestekarte.streamer" label="Anzahl Streamer_in" persistent-hint :hint="`Eintrittskarte je ${gaestekarte.termin.ticketpreis.streamer} €`" :disabled="true")
            v-col(cols="12" :md="hasStreamer(gaestekarte) ? 3 : 4"): v-text-field(v-model="gaestekarte.mitglieder" label="Anzahl Mitglieder des HVD" persistent-hint hint="Eintrittskarte je 0 €" :disabled="true")
            v-col(cols="12").title.pt-3 Gesamt: <strong>{{preisGesamt(gaestekarte)}} €</strong>
            template(v-if="gaestekarte.mitglieder - 0 > 0")
              template(v-for="mg,n in mgStrToArray(gaestekarte.mitgliederNamen)")
                v-col(cols="12" md="4"): v-text-field(:value="mg.vorname" :label="`MG-${n +1} Vorname`" disabled)
                v-col(cols="12" md="4"): v-text-field(:value="mg.nachname" :label="`MG-${n +1} Nachname`" disabled)
                v-col(cols="12" md="4"): v-text-field(:value="mg.mgNr" :label="`MG-${n +1} Mitglieds-Nr.`" disabled)
            v-col(cols="12"): v-textarea(v-model="gaestekarte.einschraenkungen" label="gfls. Einschränkungen der Gäste" :disabled="true" rows="3" auto-grow)
            v-col(cols="12"): v-textarea(v-model="gaestekarte.anmerkungen" label="gfls. zusätzliche Anmerkungen" :disabled="true" rows="3" auto-grow)
            v-col(cols="12" v-if="gaestekarte.termin.ort.kuerzel !== 'FSP'")
              | Lokale Zeitungen berichten regelmäßig über die JugendFEIERn. Meist wird ein Gruppenbild von allen Teilnehmern erstellt, entweder durch Pressefotografen oder durch die von uns beauftragten offiziellen Partner. Die Namen der auf diesen Gruppenbildern Abgebildeten werden bei uns angefragt.
              radio-group(row :value="gaestekarte.nameAnPresse" disabled :items="[{ text: 'Ja', value: true }, { text: 'Nein', value: false }]" label="Ich bin damit einverstanden, dass der HVD Berlin-Brandenburg KdöR den / die Namen meines Kindes / meiner Kinder an die Presse weitergibt.")
            v-col(cols="12" v-if="gaestekarte.termin.gaestekarteFrontendUpdateable && gaestekarte.teilnehmerGebuchtAnzahl > 0")
              v-btn(color="primary" @click="editGkTermin = gaestekarte.termin.id") Gästekartenbestellung ändern
            v-col(cols="12").pt-3: div(v-if="gaestekarte.termin.ort.ruecktrittsbedingungenGk" v-html="gaestekarte.termin.ort.ruecktrittsbedingungenGk")

  template(v-else-if="successMsg")
    div(v-html="html[0]")
    v-btn(@click="successMsg = null") Zurück
  template(v-else-if="!user")
    p Daten laden fehlgeschlagen.
  template(v-else)
    template(v-if="gaestekarten.length === 0")
      p Sie haben noch keine Teilnehmer Gebucht und können daher keine Gästekarten bestellen
    template(v-else)
      v-alert.mb-3(:value="gqlHasErrorsFor('gaestekarteOrder')" dismissible @input="gqlClearErrorsFor('gaestekarteOrder')")
        .subheading Bitte überprüfen Sie ihre Eingaben.
        template(v-for="error in gqlErrorsFor('gaestekarteOrder', 'base')") {{error}}<br>
        template(v-for="error in gqlErrorsFor('gaestekarteOrder')") {{error}}<br>
      template(v-for="gaestekarte, i in gaestekarten")
        v-card.mb-3
          v-card-title.title.grey.lighten-3 {{strftime(gaestekarte.termin.zeit, "dddd D. MMM Y H:mm [Uhr]")}} {{gaestekarte.termin.ort.adverbial}} {{gaestekarte.termin.ort.name}}
          v-card-text.pt-4
            template(v-if="!gaestekarte.termin.hatGaestekartenbestellzeitraum")
              p Die Gästekarten können sie hier zu einem späteren Zeitpunkt bestellen.
            template(v-else-if="gaestekarte.termin.gaestekarteFrontendUpdateableInFuture")
              p Die Gästekarten können sie hier ab dem {{strftime(gaestekarte.termin.gestekartenbestellungAb, 'D.M.Y')}} bestellen.
            template(v-else)
              template(v-if="gaestekarte.teilnehmerGebuchtAnzahl === 0")
                p Sie haben keine Teilnehmer gebucht und können daher keine Gästekarten bestellen
              template(v-else)
                template(v-if="!gaestekarte.termin.gaestekarteFrontendUpdateable")
                  p Die Online-Gästekartenbestellung für diesen Termin ist geschlossen.
                  //- p: strong Gerne nehmen wir Ihre Bestellungen und Änderungswünsche ab sofort persönlich entgegen: per Mail unter {{constants && constants.infoMail}} oder telefonisch unter {{constants && constants.infoPhone}}.
                template(v-else)
                  //- p: strong Bitte bestellen Sie bis zum {{strftime(gaestekarte.termin.gestekartenbestellungBis, 'D.M.Y')}} Ihre Gästekarten für die Festveranstaltung
                  //- p Die Eintrittskarte Ihres Kindes bestellen Sie bitte NICHT mit, da diese bereits in der Teilnahmegebühr enthalten ist und Ihnen automatisch mit ihren bestellten Karten zugesandt wird. Bis zum {{strftime(gaestekarte.termin.gestekartenbestellungBis, 'D.M.Y')}} können Sie Ihre Bestellung jederzeit im unten stehenden Formular anpassen.
                  //- p(v-if="gaestekarte.termin.garantierteGaestekarten")
                    | Wir garantieren jeder Familie {{gaestekarte.termin.garantierteGaestekarten}} Eintrittskarten, wenn die Bestellung bis zum {{strftime(gaestekarte.termin.gestekartenbestellungBis, 'D.M.Y')}} bei uns eingeht. Selbstverständlich können Sie auch mehr Karten bestellen. Sollte die Platzkapazität jedoch insgesamt überschritten werden, melden wir uns bei Ihnen, um eine Kartenreduzierung vorzunehmen. Dies ist sehr selten erforderlich.
                    //- template(v-if="gaestekarte.termin.ort.kuerzel == 'FSP'")
                      br
                      | Die Vergabe der Karten an die einzelnen Familien erfolgt in der Reihenfolge der Bestelleingänge in unserem Büro.
                  //- p
                    | Der Veranstaltungsort ist grundsätzlich barrierefrei, trotzdem sollten Sie uns über eventuelle Einschränkungen informieren, damit wir Sie und Ihre Gäste bestmöglich platzieren können. 
                    //- template(v-if="gaestekarte.termin.ort.kuerzel == 'FSP'")  Da der Veranstaltungsort nur über eine begrenzte Anzahl von Rollstuhlplätzen verfügt, bitten wir Sie, diese nur in dringenden Fällen zu bestellen.
                v-row
                  template(v-if="gaestekarte.saalplan")
                    //- v-col(cols="12")
                      v-card(flat).mt-3: v-card-text
                        template(v-for="tn, tnI in gaestekarte.teilnehmer.filter((t) => t.gebucht)")
                          v-row(wrap)
                            v-col(cols="12" v-if="tnI > 0 && $vuetify.breakpoint.smAndDown"): hr.grey.lighten-3
                            v-col(cols="12" md="4"): v-text-field(label="Ticket" value="Teilnehmer" disabled)
                            v-col(cols="12" sm="6" md="4"): v-text-field(label="Vorname" v-model="tn.vorname" disabled)
                            v-col(cols="12" sm="6" md="4"): v-text-field(label="Nachname" v-model="tn.nachname" disabled)
                    //- v-col(cols="12" v-if="$vuetify.breakpoint.smAndDown && gaestekarte.teilnehmer.filter((t) => t.gebucht).length && gaestekarte.gkBestellungen.length")
                      hr.mx-4.grey.lighten-3
                    v-col(cols="12" v-if="hatKeineBestellungen(gaestekarte) && !gaestekarte.ichMoechteKeineKarten")
                      v-alert(type="warning") Es wurden 0 Tickets bestellt
                    template(v-for="bestellung in gaestekarte.gkBestellungen")
                      v-col(cols="12")
                        v-card(flat).mt-3: v-card-text
                          template(v-for="platz, platzI in bestellung.plaetze")
                            v-row(wrap)
                              v-col(cols="12" v-if="platzI > 0 && $vuetify.breakpoint.smAndDown"): hr.grey.lighten-3
                              template(v-if="isPersonalisierbar(gaestekarte, platz)")
                                v-col(cols="12" sm="6" md="3"): v-text-field(label="Ticket" :value="`${platz.ticketart} ${platz.kategorie}`" :hint="`${platzName(platz)} ${platz.storno ? 'Storniert' : '' }`" persistent-hint disabled)
                                v-col(cols="12" sm="6" md="3"): v-text-field(label="Vorname" v-model="platz.vorname" disabled)
                                v-col(cols="12" sm="6" md="3"): v-text-field(label="Nachname" v-model="platz.nachname" disabled)
                                v-col(cols="12" sm="6" md="3"): v-text-field(label="E-Mail/Tel/Adresse" v-model="platz.kontakt" disabled)
                              template(v-else)
                                v-col(cols="12"): v-text-field(label="Ticket" :value="`${platz.ticketart} ${platz.kategorie}`" :hint="`${platzName(platz)} ${platz.storno ? 'Storniert' : '' }`" persistent-hint disabled)

                    template(v-if="gaestekarte.gkBestellungen.length > 0")
                      //- v-col(cols="12" v-if="gaestekarte.rollstuhl"): v-checkbox(v-model="gaestekarte.rollstuhl" label="Rollstuhlplatz benötigt" :disabled="true" hide-details)
                      v-col(cols="12"): v-textarea(v-model="gaestekarte.anmerkungen" label="gfls. zusätzliche Anmerkungen" :disabled="true" rows="3" auto-grow)
                      v-col(cols="12" v-if="gaestekarte.termin.nameAnPresse")
                        div(v-html="constants.nameAnPresseHtml")
                        radio-group(row :value="gaestekarte.nameAnPresse" disabled :items="[{ text: 'Ja', value: true }, { text: 'Nein', value: false }]" :mandatory="false" label="Ich bin damit einverstanden, dass die Namen meines Kindes / meiner Kinder an die Presse weitergegeben werden.")

                    v-col(cols="12" v-if="gaestekarte.termin.gaestekarteFrontendUpdateable")
                      template(v-if="gaestekarte.gkBestellungen.length == 0")
                        v-btn(color="primary" @click="editGkI = i; editGkedit = 'new'") Gästekarten bestellen
                      template(v-else)
                        v-btn(color="primary" @click="editGkI = i; editGkedit = 'names'") Gästekarten bearbeiten

                  v-col(cols="12").pt-3: div(v-if="gaestekarte.termin.ort.ruecktrittsbedingungenGk" v-html="gaestekarte.termin.ort.ruecktrittsbedingungenGk")

  v-dialog(:value="!!editGk" @input="editGkI = null" persistent)
    template(v-if="editGk && editGk.saalplan && saalplan")
      v-card
        v-card-title.title.grey.lighten-3
          | {{strftime(editGk.termin.zeit, "dddd D. MMM Y H:mm [Uhr]")}} {{editGk.termin.ort.adverbial}} {{editGk.termin.ort.name}}
          v-spacer
          v-btn(@click="closeGkModal()" outlined icon): v-icon mdi-close
        v-card-text.pt-3
          v-alert.mb-3(:value="gqlHasErrorsFor('gaestekarteOrder')" dismissible @input="gqlClearErrorsFor('gaestekarteOrder')" type="error")
            .subheading Bitte überprüfen Sie ihre Eingaben.
            template(v-for="error in gqlErrorsFor('gaestekarteOrder', 'base')") {{error}}<br>
            template(v-for="error in gqlErrorsFor('gaestekarteOrder')") {{error}}<br>
          //- v-row
            v-col(cols="12"): v-checkbox(label="Ich brauche keine Gästekarten" v-model="braucheKeineGk" @click="editGk.erwachsene = '0'; editGk.kinder = '0'; editGk.streamer = '0'; editGk.mitglieder = '0'")
            template(v-if="!braucheKeineGk")
              v-col(cols="12" :md="hasStreamer(editGk) ? 3 : 4"): v-text-field(v-model="editGk.erwachsene" label="Anzahl Erwachsene" persistent-hint :hint="`Eintrittskarte je ${editGk.termin.ticketpreis.gast} €`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'erwachsene')")
              v-col(cols="12" :md="hasStreamer(editGk) ? 3 : 4"): v-text-field(v-model="editGk.kinder" label="Anzahl Kinder" persistent-hint :hint="`Eintrittskarte je ${editGk.termin.ticketpreis.gastKind} €  (bis einschließlich 13 Jahre)`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'kinder')")
              v-col(cols="12" v-if="hasStreamer(editGk)" md="3"): v-text-field(v-model="editGk.streamer" label="Anzahl Streamer_in" persistent-hint :hint="`Eintrittskarte je ${editGk.termin.ticketpreis.streamer} €`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'streamer')")
              v-col(cols="12" :md="hasStreamer(editGk) ? 3 : 4"): v-text-field(v-model="editGk.mitglieder" label="Anzahl Mitglieder des HVD" persistent-hint hint="Eintrittskarte je 0 €" :error-messages="gqlErrorsFor('gaestekarteOrder', 'mitglieder')")
            v-col(cols="12").title.pt-3 Gesamt: <strong>{{preisGesamt(editGk)}} €</strong>
            template(v-for="mg,n in editGk.mgNamen")
              v-col(cols="12" md="4"): v-text-field(v-model="mg.vorname" :label="`MG-${n +1} Vorname`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'mitgliederNamen', n, 'vorname')")
              v-col(cols="12" md="4"): v-text-field(v-model="mg.nachname" :label="`MG-${n +1} Nachname`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'mitgliederNamen', n, 'nachname')")
              v-col(cols="12" md="4"): v-text-field(v-model="mg.mgNr" :label="`MG-${n +1} Mitglieds-Nr.`" :error-messages="gqlErrorsFor('gaestekarteOrder', 'mitgliederNamen', n, 'nr')")
            template(v-if="!braucheKeineGk")
              v-col(cols="12"): v-textarea(v-model="editGk.einschraenkungen" label="gfls. Einschränkungen der Gäste" rows="3" auto-grow :error-messages="gqlErrorsFor('gaestekarteOrder', 'einschraenkungen')")
            v-col(cols="12"): v-textarea(v-model="editGk.anmerkungen" label="gfls. zusätzliche Anmerkungen" rows="3" auto-grow :error-messages="gqlErrorsFor('gaestekarteOrder', 'anmerkungen')")
            //- v-col(cols="12" v-if="editGk.termin.ort.kuerzel !== 'FSP'")
              | Lokale Zeitungen berichten regelmäßig über die JugendFEIERn. Meist wird ein Gruppenbild von allen Teilnehmern erstellt, entweder durch Pressefotografen oder durch die von uns beauftragten offiziellen Partner. Die Namen der auf diesen Gruppenbildern Abgebildeten werden bei uns angefragt.
              radio-group(row v-model="editGk.nameAnPresse" :items="[{ text: 'Ja', value: true }, { text: 'Nein', value: false }]" label="Ich bin damit einverstanden, dass der HVD Berlin-Brandenburg KdöR den / die Namen meines Kindes / meiner Kinder an die Presse weitergibt." :error-messages="nameAnPresseError")
            v-col(cols="12")
              v-btn(color="primary" @click="save(editGk)" :loading="loading > 0") {{braucheKeineGk ? 'Speichern' : editGk.termin.saalplanId ? 'Plätze Auswählen' : 'Gästekarten Bestellen'}}
              v-btn(@click="editGkI = null") Abbrechen
          v-row.pt-2
            template(v-if="editGkedit == 'names'")
              v-col(cols="12" v-if="isPersonalisierbar(editGk, null)").title
                | <strong>INFO:</strong> Der Gästekartenversand erfolgt erst nach vollständiger Personalisierung der Gästekarten. Bitte füllen Sie alle Felder aus.
              //- v-col(cols="12")
                v-card.mt-3: v-card-text
                  template(v-for="tn, tnI in editGk.teilnehmer.filter((t) => t.gebucht)")
                    v-row(wrap)
                      v-col(cols="12" v-if="tnI > 0 && $vuetify.breakpoint.smAndDown"): hr.grey.lighten-3
                      v-col(cols="12" md="4"): v-text-field(label="Ticket" value="Teilnehmer" disabled)
                      v-col(cols="12" sm="6" md="4"): v-text-field(label="Vorname" v-model="tn.vorname" disabled)
                      v-col(cols="12" sm="6" md="4"): v-text-field(label="Nachname" v-model="tn.nachname" disabled)

              v-col(cols="12" v-if="hatKeineBestellungen(editGk) && !editGk.ichMoechteKeineKarten")
                v-alert(type="warning") Es wurden 0 Tickets bestellt
              template(v-for="bestellung in editGk.gkBestellungen")
                v-col(cols="12")
                  v-card.mt-3: v-card-text
                    template(v-for="platz, platzI in bestellung.plaetze")
                      v-row(wrap)
                        v-col(cols="12" v-if="platzI > 0 && $vuetify.breakpoint.smAndDown"): hr.grey.lighten-3
                        template(v-if="isPersonalisierbar(editGk, platz)")
                          v-col(cols="12" sm="6" md="3"): v-text-field(label="Ticket" :value="`${platz.ticketart} ${platz.kategorie}`" :hint="`${platzName(platz)} ${platz.storno ? 'Storniert' : '' }`" persistent-hint disabled)
                          v-col(cols="12" sm="6" md="3"): v-text-field(label="Vorname" v-model="platz.vorname")
                          v-col(cols="12" sm="6" md="3"): v-text-field(label="Nachname" v-model="platz.nachname")
                          v-col(cols="12" sm="6" md="3"): v-text-field(label="E-Mail/Tel/Adresse" v-model="platz.kontakt")
                        template(v-else)
                          v-col(cols="12"): v-text-field(label="Ticket" :value="`${platz.ticketart} ${platz.kategorie}`" :hint="`${platzName(platz)} ${platz.storno ? 'Storniert' : '' }`" persistent-hint disabled)
                    .d-flex(v-if="bestellung.status == 'Offen'")
                      v-spacer
                      v-btn.mr-2(@click="editGkedit = 'new'" outlined) Weitere Tickets
                      v-btn(@click="editGkedit = 'remove'" outlined) Tickets Entfernen
              v-col(cols="12" v-if="!editGk.gkBestellungen.find((b) => b.status == 'Offen')")
                v-btn.mr-2(@click="editGkedit = 'new'" outlined) Weitere Tickets
              //- v-col(cols="12" v-if="editGk.rollstuhl"): v-checkbox(v-model="editGk.rollstuhl" label="Rollstuhlplatz benötigt"  hide-details)
              v-col(cols="12"): v-textarea(v-model="editGk.anmerkungen" label="gfls. zusätzliche Anmerkungen" rows="3" auto-grow)
              v-col(cols="12" v-if="editGk.termin.nameAnPresse")
                div(v-html="constants.nameAnPresseHtml")
                radio-group(row v-model="editGk.nameAnPresse" :items="[{ text: 'Ja', value: true }, { text: 'Nein', value: false }]" :mandatory="false" :error-messages="gqlErrorsFor('gaestekarteOrder', 'name_an_presse')" label="Ich bin damit einverstanden, dass die Namen meines Kindes / meiner Kinder an die Presse weitergegeben werden.")
              v-col(cols="12")
                v-btn(color="primary" @click="save(editGk, null)") Speichern
                v-btn(@click="closeGkModal()") Abbrechen

            template(v-else-if="editGkedit == 'remove'")
              v-col(cols="12" v-if="hatKeineBestellungen(editGk) && !editGk.ichMoechteKeineKarten")
                v-alert(type="warning") Es wurden 0 Tickets bestellt
              template(v-for="bestellung in editGk.gkBestellungen")
                v-col(cols="12" v-if="bestellung.status == 'Offen'")
                  v-card.mt-3: v-card-text
                    template(v-for="platz in bestellung.plaetze")
                      v-row(wrap :xclass="platz.destroy ? 'error white--text' : 'success white--text'")
                        v-col(cols="5"): v-text-field(label="Ticket" :value="`${platz.ticketart} ${platz.kategorie}`" :hint="`${platzName(platz)} ${platz.storno ? 'Storniert' : '' }`" persistent-hint disabled)
                        v-col(cols="3"): v-text-field(label="Vorname" :value="platz.vorname" disabled)
                        v-col(cols="3"): v-text-field(label="Nachname" :value="platz.nachname" disabled)
                        //- v-col(cols="1").text-right: v-btn(@click="platz.destroy = !platz.destroy" icon :class="platz.destroy ? 'error white--text' : ''"): v-icon mdi-delete
                        v-col(cols="1").text-right: v-checkbox(v-model="platz.destroy")
              v-col(cols="12")
                v-btn(color="primary" @click="save(editGk, null)") Entfernen
                v-btn(@click="closeGkModal()") Abbrechen
            template(v-else-if="!showSaalplan")
              v-col(cols="12" md="9")
              v-col(cols="12" md="3")
                v-btn(@click="save(editGk)" :loading="loading > 0" outlined color="primary" :disabled="editGk.ichMoechteKeineKarten || ticketAnzahl(editGk.preise, true) == 0")
                  | {{ticketAnzahl(editGk.preise, true)}} Tickets kaufen, {{ticketPreis(editGk.preise, true)}} €
              //- v-col(v-if="!braucheKeineGk" cols="12")
                template(v-for="kat in saalplan.preiskategorien")
                  .pa-2.mr-1.d-inline-block.white--text(:class="kat.color") {{kat.name}}
                  //- v-btn.white--text(:class="kat.color") {{kat.name}}
              //- template(v-if="!braucheKeineGk" v-for="preis, name in editGk.preise[art.name]")
              template(v-for="kat in saalplan.preiskategorien")
                v-col(cols="12")
                  v-card.mb-3
                    v-row(wrap)
                      v-col(cols="12" md="3")
                        strong.title.pa-2 {{kat.name}}
                      v-col(cols="12" md="6")
                        .pa-2
                          template(v-for="preis, name, preisI in editGk.preise[kat.name]")
                            hr(v-if="preisI > 0").grey.lighten-5.my-2
                            .d-flex(class="$vuetify.breakpoint.sm ? 'flex-column' : ''")
                              .pt-2 {{name}}
                              v-spacer
                              div.pl-2
                                | {{preis.p}} € &nbsp;
                                v-btn(icon @click="preis.anzahl++; editGk.ichMoechteKeineKarten = false" fab outlined small color="primary"): v-icon mdi-plus
                                span.text-center.d-inline-block(style="width: 2em") {{preis.anzahl}}
                                v-btn(icon @click="preis.anzahl--" fab outlined small color="primary" :disabled="preis.anzahl == 0"): v-icon mdi-minus

                      v-col(cols="12" md="3"): .pa-2
                        //- v-btn(@click="save(editGk, kat.name)" :loading="loading > 0" outlined color="primary" :disabled="ticketAnzahl(editGk.preise[kat.name]) == 0")
                          | {{ticketAnzahl(editGk.preise[kat.name])}} Tickets, {{ticketPreis(editGk.preise[kat.name])}} €

              v-col(cols="12" v-if="hatKeineBestellungen")
                v-card.mb-3
                  v-row(wrap)
                    v-col(cols="12" md="3")
                    v-col(cols="12" md="6")
                        v-checkbox(label="Ich brauche keine Gästekarten" v-model="editGk.ichMoechteKeineKarten" @change="clearBestellungen" hide-details)
                    v-col(cols="12" md="3")
                      .px-2.py-3: v-btn(@click="save(editGk, false)" :loading="loading > 0" outlined color="primary" :disabled="!editGk.ichMoechteKeineKarten") Keine Karten

              v-col(cols="12")
                v-btn(@click="save(editGk)" :loading="loading > 0" outlined color="primary" :disabled="editGk.ichMoechteKeineKarten || ticketAnzahl(editGk.preise, true) == 0")
                  | {{ticketAnzahl(editGk.preise, true)}} Tickets kaufen, {{ticketPreis(editGk.preise, true)}} €
                v-btn(@click="closeGkModal()") Abbrechen

                //- v-col(cols="3")
                    //- v-btn.block(@click="addPlatz(art)")
                      v-icon mdi-plus
                      | {{art.name}}
                    //- pre {{editGk}}
                    //- | {{editGk.anzahl}}
                    v-text-field(:label="art.name" v-model="editGk.anzahl[art.name]" dense)
                  v-col(cols="3")
                    //- | {{ editGk.saalplanBuchungen.filter((b) => b.art == art.id).length }}
                    //- pre {{editGk.preise}}
                    template(v-for="preis, name in editGk.preise[art.name]")
                      .pa-2.mr-1.d-inline-block.white--text(:class="preis.color") {{preis.p}} €
                  v-col(cols="6")

              //- v-col(cols="12" v-if="editGk.saalplanBuchungen.length == 0")
                //- v-checkbox(label="Ich brauche keine Gästekarten" v-model="braucheKeineGk" @click="editGk.erwachsene = '0'; editGk.kinder = '0'; editGk.streamer = '0'; editGk.mitglieder = '0'")
                v-checkbox(label="Ich brauche keine Gästekarten" v-model="braucheKeineGk")

              //- template(v-for="buchung, buchungI in editGk.saalplanBuchungen")
                v-col(cols="12" md="3") {{artText(buchung.art)}}
                v-col(cols="12" md="3")
                  template(v-if="buchung.id")
                    v-icon(left) mdi-seat
                    | {{platzText(buchung.platz)}}
                  template(v-else-if="hatPlatzwahl(buchung.art)")
                    v-btn(@click="platzWaehlen(buchung)" :color="buchung == editBuchung ? 'primary' : ''")
                      v-icon(left) mdi-seat
                      | {{buchung.platz ? buchung.platz : 'Platz Wählen'}}
                    v-btn(@click="removeBuchung(buchungI, buchung)" icon)
                      v-icon mdi-delete
                  template(v-else)
                    v-btn(@click="removeBuchung(buchungI, buchung)" icon)
                      v-icon mdi-delete
                v-col(cols="12" md="6")
            template(v-else)
                //- v-row(style="max-width: 100%")
                v-col(cols="12" md="8")
                  saalplan-show(:value="saalplan" :selecting="!!editBuchung" :show="showPlatz" @platz="platzSelected" title)
                v-col(cols="12" md="4")
                  v-btn.mb-1(@click="save(editGk)" :loading="loading > 0" outlined color="primary" :disabled="platzFehler || editGk.ichMoechteKeineKarten || (editPlaetze.length + ticketAnzahl(editGk.preise, true)) == 0")
                    | {{ticketAnzahl(editGk.preise, true)}} Tickets kaufen, {{ticketPreis(editGk.preise, true)}} €
                  v-alert.mb-1(:value="platzFehler" type="error") Bitte lass keine Lücken. (Mindestens {{editGk.saalplan.minPlaetzeAbstand}} Plätze abstand)

                  template(v-for="buchung, buchungI in editPlaetze")
                    v-card.mb-1
                      v-card-text
                        div
                          v-btn(style="float: right" icon @click="platzSelected(buchung)"): v-icon mdi-close
                          .px-1.mr-1(style="border-radius: 100%; width: 1.6em; height: 1.6em; display: inline-block;" :class="buchung.color") &nbsp;
                          | {{buchung.preiskategorieName}}
                          span  {{stuhlart(buchung.stuhlart)}}
                        div
                          span(v-if="zugaengNameById[buchung.zugang]")
                            |  Zugang
                            strong  {{zugaengNameById[buchung.zugang]}}
                          span(v-if="blockNameById[buchung.block]")
                            |  Block
                            strong  {{blockNameById[buchung.block]}}
                          span(v-if="buchung.reihe")
                            |  Reihe
                            strong  {{buchung.reihe}}
                          span(v-if="buchung.platznummer")
                            |  Platz
                            strong  {{buchung.platznummer}}
                        v-select(v-model="buchung.art" :items="getTicketArtenByIdStuhlPreis(buchung)" item-text="name" item-value="id" hide-details dense)

                  template(v-for="kat in saalplan.preiskategorien")
                    v-card.mb-1(v-if="editGk.preise[kat.name]")
                      v-card-text
                        .px-1.mr-1(style="border-radius: 100%; width: 1.6em; height: 1.6em; display: inline-block;" :class="kat.color") &nbsp;
                        | {{kat.name}}
                        template(v-for="preis, name, preisI in editGk.preise[kat.name]")
                          div(v-if="preisI > 0" style="clear: both")
                          hr(v-if="preisI > 0").grey.lighten-5.my-2
                          div
                            .pt-2(style="float: left") {{name}}
                            .pl-2(style="float: right")
                              | {{preis.p}} € &nbsp;
                              v-btn(icon @click="preis.anzahl++; editGk.ichMoechteKeineKarten = false" fab outlined small color="primary"): v-icon mdi-plus
                              span.text-center.d-inline-block(style="width: 2em") {{preis.anzahl}}
                              v-btn(icon @click="preis.anzahl--" fab outlined small color="primary" :disabled="preis.anzahl == 0"): v-icon mdi-minus
                        div(style="clear: both")

                  //- template(v-for="kat in saalplan.preiskategorien")
                            //- v-col(cols="12")
                            v-card.mb-1(v-if="editGk.preise[kat.name]")
                              v-row(wrap)
                                v-col(cols="12" md="3")
                                  strong.title.pa-2 {{kat.name}}
                                v-col(cols="12" md="6")
                                  .pa-2
                                    template(v-for="preis, name, preisI in editGk.preise[kat.name]")
                                      hr(v-if="preisI > 0").grey.lighten-5.my-2
                                      //- pre {{preis}}
                                      .d-flex(class="$vuetify.breakpoint.sm ? 'flex-column' : ''")
                                        .pt-2 {{name}}
                                        v-spacer
                                        div.pl-2
                                          | {{preis.p}} € &nbsp;
                                          v-btn(icon @click="preis.anzahl++; editGk.ichMoechteKeineKarten = false" fab outlined small color="primary"): v-icon mdi-plus
                                          span.text-center.d-inline-block(style="width: 2em") {{preis.anzahl}}
                                          v-btn(icon @click="preis.anzahl--" fab outlined small color="primary" :disabled="preis.anzahl == 0"): v-icon mdi-minus

                                v-col(cols="12" md="3"): .pa-2
                                  //- v-btn(@click="save(editGk, kat.name)" :loading="loading > 0" outlined color="primary" :disabled="ticketAnzahl(editGk.preise[kat.name]) == 0")
                                    | {{ticketAnzahl(editGk.preise[kat.name])}} Tickets, {{ticketPreis(editGk.preise[kat.name])}} €

                  v-alert.mb-1(:value="platzFehler" type="error") Bitte lass keine Lücken. (Mindestens {{editGk.saalplan.minPlaetzeAbstand}} Plätze abstand)
                  v-btn(v-if="ticketAnzahl(editGk.preise, true) > 0" @click="save(editGk)" :loading="loading > 0" outlined color="primary" :disabled="platzFehler || editGk.ichMoechteKeineKarten || (editPlaetze.length + ticketAnzahl(editGk.preise, true)) == 0")
                    | {{ticketAnzahl(editGk.preise, true)}} Tickets kaufen, {{ticketPreis(editGk.preise, true)}} €

                v-col(cols="12" v-if="hatKeineBestellungen && editGk.ichMoechteKeineKarten || (editPlaetze.length + ticketAnzahl(editGk.preise, true)) == 0")
                  v-card.mb-3
                    v-row(wrap)
                      v-col(cols="12" md="3")
                      v-col(cols="12" md="6")
                          v-checkbox(label="Ich brauche keine Gästekarten" v-model="editGk.ichMoechteKeineKarten" @change="clearBestellungen" hide-details)
                      v-col(cols="12" md="3")
                        .px-2.py-3: v-btn(@click="save(editGk, false)" :loading="loading > 0" outlined color="primary" :disabled="!editGk.ichMoechteKeineKarten") Keine Karten

      //- v-card
        v-card-actions
          //- v-btn(color="primary" @click="save(editGk)" :loading="loading > 0") Gästekarten Bestellen
          v-spacer
          v-btn(@click="editGkI = null" outlined) Abbrechen

  //- v-dialog(:value="!!saalplan" @input="saalplan = null" :max-width="saalplan && saalplan.width ? `${+saalplan.width*saalplan.scale}em` : null" ref="dialog")
    v-card(v-if="editGk && saalplan")
      v-card-title.title.grey.lighten-3 {{strftime(editGk.termin.zeit, "dddd D. MMM Y H:mm [Uhr]")}} {{editGk.termin.ort.adverbial}} {{editGk.termin.ort.name}}
      //- v-card-text
        //- saalplan-show(:value="saalplan")
      saalplan-show(:value="saalplan" :show="showPlatz" @platz="platzSelected")
      v-alert.mb-3(:value="gqlHasErrorsFor('gaestekarteOrder')" dismissible @input="gqlClearErrorsFor('gaestekarteOrder')" type="error")
        template(v-for="error in gqlErrorsFor('gaestekarteOrder', 'base')") {{error}}<br>
        template(v-for="error in gqlErrorsFor('gaestekarteOrder')") {{error}}<br>
      .pa-3
        v-btn(@click="savePlaetze" :loading="loading > 0" color="primary") Gästekarten Bestellen
        //- v-btn(@click="showPlatz = showPlatz == 'platz' ? 'block' : 'platz'") Show

</template>

<style lang="sass" scoped>
.extra-guest-ticket-booking {
  ::v-deep label {
    font-weight: normal;
  }
}
</style>

<script>
import graphqlErrorObject from '../backend/mixins/graphql-error-object.js'
import QueryFilter from '../backend/mixins/query-filter'
import gql from 'gql-id.macro'
import gqlTag from 'graphql-tag'
import moment from 'moment'
import { sortBy, uniqBy, sum, isArray } from 'lodash'
import SaalplanShow from '../backend/components/saalplan-show'

export default {
  props: {
    value: Object,
  },
  mixins: [graphqlErrorObject, QueryFilter],
  components: {
    SaalplanShow
  },
  data() {
    return {
      editGk: null,
      editGkI: null,
      editGkedit: null,
      newGks: null,
      editPlaetze: [],
      // editPlatz: null,
      editBuchung: null,
      successMsg: null,
      showPlatz: 'blank',
      saalplan: null,
      saalplaene: [],
      nameAnPresseError: [],
      loading: 0,
      constants: {},
    }
  },
  apollo: {
    saalplanBuchungen: {
      query: gql`query JfGaestekartenVueBuchungen($terminId: ID) {
        saalplanBuchungen(where: { terminId: $terminId }, role: USER) {
          id
          platz
        }
      }`,
      variables() {
        return {
          terminId: this.editGk.termin.id
        }
      },
      skip() {
        return !this.editGk?.termin?.id
      },
      fetchPolicy: 'cache-and-network',
      subscribeToMore: {
        // see app/channels/graphql_channel.rb
        document: gqlTag`
          subscription JfGaestekartenVueBuchungenSub($terminId: ID!) {
            saalplanBuchungChanged(termin: $terminId) {
              id
              action
              buchung {
                id
                platz
                __typename
              }
              __typename
            }
          }
        `,
        variables() {
          return {
            terminId: this.editGk.termin.id
          }
        },
        skip() {
          return !this.editGk?.termin?.id
        },
        updateQuery(previousResult, { subscriptionData }) {
          if (previousResult.saalplanBuchungen && subscriptionData.data?.saalplanBuchungChanged) {
            const id = subscriptionData.data.saalplanBuchungChanged.id
            if (subscriptionData.data.saalplanBuchungChanged.action === 'destroy') {
              return {
                saalplanBuchungen: [
                  ...previousResult.saalplanBuchungen.filter(b => b.id != id)
                ]
              }
            } else if (previousResult.saalplanBuchungen.find(b => b.id == id)) {
              return previousResult
            } else {
              return {
                saalplanBuchungen: [
                  ...previousResult.saalplanBuchungen,
                  subscriptionData.data.saalplanBuchungChanged.buchung
                ]
              }
            }
          } else {
            return previousResult
          }
        }
      }
    },
    user: {
      query: gql`query extraGuestTicketBookingVueUser {
        user: currentUser {
          id
          gaestekarten {
            id
            erwachsene
            kinder
            streamer
            mitglieder
            mitgliederNamen
            # einschraenkungen
            rollstuhl
            anmerkungen
            nameAnPresse
            teilnehmerGebuchtAnzahl
            hatKartenBestellt
            hatBestellungAbgegeben
            ichMoechteKeineKarten
            teilnehmer {
              id
              vorname
              nachname
              gebucht
            }
            gkBestellungen {
              id
              status
              plaetze {
                id
                kategorie
                ticketart
                sitzart
                block
                reihe
                platz
                vorname
                nachname
                kontakt
                preis
                storno
              }
            }
            saalplanBuchungen {
              id
              platz
              art
            }
            termin {
              id
              zeit
              garantierteGaestekarten
              gestekartenbestellungAb
              gestekartenbestellungBis
              hatGaestekartenbestellzeitraum
              gaestekarteFrontendUpdateable
              gaestekarteFrontendUpdateableInFuture
              gaestekartenBestallungHatBegonnen
              nameAnPresse
              ticketpreis {
                id
                gast
                gastKind
                streamer
              }
              ort {
                id
                adverbial
                name
                kuerzel
                ruecktrittsbedingungenGk
              }
              saalplanId
              saalplanBuchungen {
                id
                platz
              }
              #gkPlaetzeFrei
              # {
              #  kategorie
              #  sitzart
              #  anzahl
              # }
            }
          }
          teilnehmer {
            id
            status
            termin {
              id
              zeit
              garantierteGaestekarten
              gestekartenbestellungAb
              gestekartenbestellungBis
              hatGaestekartenbestellzeitraum
              gaestekarteFrontendUpdateable
              gaestekarteFrontendUpdateableInFuture
              gaestekartenBestallungHatBegonnen
              saalplanId
              ticketpreis {
                id
                gast
                gastKind
                streamer
              }
              ort {
                id
                adverbial
                name
                kuerzel
                ruecktrittsbedingungenGk
              }
            }
          }
        }
      }`,
      update(response) {
        const user = response.user
        if (user) {
          user.gaestekarten.forEach((gk) => {
            gk.gkBestellungen.forEach((b) => {
              b.plaetze.forEach((p) => {
                p.destroy = false
              })
            })
            gk.gkBestellungen = sortBy(gk.gkBestellungen, 'id')
          })
          user.gaestekarten = sortBy(user.gaestekarten, 'id')
        }
        return user
      },
    },
    constants: {
      query: gql`query extraGuestTicketBookingVueConstants {
        constants {
          infoMail
          infoPhone
          nameAnPresseHtml
        }
      }`
    },
    saalplaene: {
      query: gql`query jahrEinstellungenSaalplanIdVue($ids: [ID!]) {
        saalplaene(where: { idIn: $ids }, role: USER) {
          id
          # verbandId
          name
          width
          height
          scale
          minPlaetzeAbstand
          plaetze {
            id
            stuhlart
            nachbar
            reihe
            platznummer
            zugang
            block
            preiskategorie
            x
            y
          }
          preiskategorien {
            id
            name
            color
          }
          zugaenge {
            id
            name
            color
          }
          bloecke {
            id
            name
            color
          }
          texte {
            id
            text
            color
            bold
            x
            y
            rotation
            scale
          }
          stuhlarten {
            id
            name
            platzwahl
          }
          ticketarten {
            id
            name
            stuhlarten
            personalisierung
          }
          preise {
            id
            ticketart
            preiskategorie
            preis
          }
        }
      }`,
      variables() {
        return {
          ids: this.saalplanIds
          // id: this.editGk.termin.saalplanId,
          // verbandId: this.$store.state.verband.id,
        }
      },
      skip() {
        return this.saalplanIds.length == 0
        // return !this.editGk?.termin?.saalplanId
      },
    },
  },
  watch: {
    saalplanBuchungen: {
      deep: true,
      handler(saalplanBuchungen) {
        this.updateReserviertePlaetze(this.saalplan, saalplanBuchungen || this.saalplanBuchungen)
      },
    },
    saalplan(saalplan) {
      this.updateReserviertePlaetze(saalplan || this.saalplan, this.saalplanBuchungen)
    },
    // gaestekarten(gks) {
    //   // const i = gks.findIndex(g => g.teilnehmerGebuchtAnzahl > 0 && !g.hatBestellungAbgegeben && g.termin.gaestekarteFrontendUpdateable)
    //   // let i = gks.length - 1
    //   // if (i >= 0) {
    //   //   this.editGkI = i
    //   // }
    // },
    editGkI(gki) {
      const gk = this.gaestekarten && this.gaestekarten[gki]
      if (gk) {
       
        // this.editGk = {
        //   ...gk,
        //   mgNamen: this.mgStrToArray(gk.mitgliederNamen)
        // }
        this.editGk = gk
        this.refreshSaalplan()
        this.editBuchung = null
      } else {
        this.editGk = null
      }
    },
    // 'editGk.mitglieder'(mitglieder) {
    //   if (!this.editGk) {
    //     return
    //   }
    //   // const mgNamen = this.editGk.mgNamen
    //   // while (mitglieder - 0 > mgNamen.length && mgNamen.length < 30) {
    //   //   mgNamen.push({ vorname: '', nachname: '', mgNr: '' })
    //   // }
    //   // while (mitglieder - 0 < this.editGk.mgNamen.length) {
    //   //   mgNamen.pop()
    //   // }
    // }
  },
  computed: {
    saalplanIds() {
      if (this.user) {
        return uniqBy(this.user.gaestekarten.map((g) => g.termin.saalplanId))
      } else {
        return []
      }
    },
    showSaalplan() {
      return !!(this.saalplan && this.editGk?.saalplan?.stuhlarten?.some((s) => s.platzwahl))
    },
    html() {
      return this.value.html.split("<hr data-STEP-SEPERATOR>")
    },
    gaestekarten() {
      const user = this.user
      if (!user) {
        return []
      }
      const termine = []
      user.teilnehmer.forEach(tn => {
        if (!termine.some(t => t.id == tn.termin.id)) {
          termine.push(tn.termin)
        }
      })
      const saalplaene = this.saalplaene || []
      const gaestekarten = user.gaestekarten.map(g => {
        const anzahl = {}
        const saalplan = saalplaene.find((s) => (s.id == g.termin.saalplanId))
        // saalplan?.preiskategorien.forEach((kat) => {
        //   // k.id == p.preiskategorie
        //   anzahl[kat.name] = 0
        // })

        // const preise = {}
        // saalplan?.ticketarten.forEach((art) => {
        //   anzahl[art.name] = 0
        //   const preis = {}
        //   saalplan.preiskategorien.forEach((kat) => { 
        //     const p = saalplan.preise.find((p) => p.preiskategorie == kat.id && p.ticketart == art.id)?.preis
        //     if (p != null) {
        //       preis[kat.name] = { p, color: kat.color }
        //     }
        //   })
        //   preise[art.name] = preis
        // })
        const stuhlart_ohne_platz = saalplan?.stuhlarten.filter((s) => !s.platzwahl).map((s) => s.id)
        const preise = {}
        saalplan?.preiskategorien.forEach((kat) => { 
          const preis = {}
          saalplan.ticketarten.forEach((art) => {
            if (stuhlart_ohne_platz.indexOf(art.stuhlarten[0]) != -1) {
              anzahl[art.name] = 0
              const p = saalplan.preise.find((p) => p.preiskategorie == kat.id && p.ticketart == art.id)?.preis
              if (p != null) {
                preis[art.name] = { p, anzahl: 0, color: art.color }
              }
            }
          })
          if (Object.keys(preis).length) {
            preise[kat.name] = preis
          }
        })


          // console.log(saalplan?.preiskategorien);
          // console.log(anzahl, saalplan);

        // saalplan?.preise?.map((p) => {
        //   const kat = preiskategorien.find((k) => k.id == p.preiskategorie)
        //   anzahl[.name] = 0
        // })
        return {...g,
          erwachsene: `${g.erwachsene}`, kinder: `${g.kinder}`, streamer: `${g.streamer}`, mitglieder: `${g.mitglieder}`,
          saalplan: saalplan,
          anzahl,
          preise,
        }
      })
      const neueGaestekarten = termine.filter(t => !user.gaestekarten.some(gk => gk.termin.id == t.id))
        .map(t => {
          // FIXME add saalplan
          return {
            id: null,
            terminId: t.id,
            termin: t,
            erwachsene: '',
            kinder: '',
            streamer: '',
            mitglieder: '',
            mitgliederNamen: '',
            einschraenkungen: '',
            anmerkungen: '',
            nameAnPresse: null,
            teilnehmerGebuchtAnzahl: user.teilnehmer.filter(tn => tn.termin.id === t.id && tn.status === "Gebucht").length
          }
        })
      return sortBy([].concat(gaestekarten, neueGaestekarten.filter(gk => gk.teilnehmerGebuchtAnzahl > 0)), 'termin.zeit')
    },
    neueWartelisten() {
      if (this.user) {
        return this.user.wartelisten.filter(w => w.status === 'Neu')
      } else {
        return []
      }
    },
    gruppe() {
      if (this.user) {
        return this.user.gruppen.filter(g => g.termin).reverse()[0]
      }
    },
    termin() {
      if (this.gruppe) {
        return this.gruppe.termin
      } else if (this.user) {
        const tn = this.user.teilnehmer.reverse().map(tn => tn.termin)
        return tn.filter(t => t)[0]
      }
    },
    reservedKinder() {
      if (!this.user) {
        return 0
      }
      return this.user.teilnehmer.filter(t => !t.komplett).length
    },
    anzahlPlaetze() {
      const gk = this.editGk || {}
      return +gk.erwachsene + +gk.kinder + +gk.mitglieder
    },
    ticketarten() {
      return this.editGk?.saalplan?.ticketarten || []
    },
    stuhlarten() {
      return this.editGk?.saalplan?.stuhlarten || []
    },
    zugaengNameById() {
      const zugaenge = {}
      this.editGk?.saalplan?.zugaenge?.forEach((z) => {
        zugaenge[z.id] = z.name
      })
      return zugaenge
    },
    blockNameById() {
      const bloecke = {}
      this.editGk?.saalplan?.bloecke?.forEach((b) => {
        bloecke[b.id] = b.name
      })
      return bloecke
    },
    platzFehler() {
      const minPlaetze = this.editGk.saalplan.minPlaetzeAbstand || 0
      if (minPlaetze <= 1) {
        return false
      }
      return this.editPlaetze.some((platz) => {
        const left = this.platzFrei(platz, minPlaetze, 'nachbarL')
        if (left == 0) {
          return false
        }
        const right = this.platzFrei(platz, minPlaetze, 'nachbarR')
        if (right == 0) {
          return false
        } else if (left < minPlaetze || right < minPlaetze) {
          return true
        } else {
          return false
        }
      })
    },
    ticketArtenByIdStuhlPreis() {
      const saalplan = this.editGk.saalplan
      const ticketarten = saalplan.ticketarten
      const arten = {}
      saalplan.preiskategorien.forEach((kat) => {
        const arten2 = {}
        saalplan.stuhlarten.forEach((stuhlart) => {
          arten2[stuhlart.id] = ticketarten.filter((t) => t.stuhlarten.indexOf(stuhlart.id) > -1).map((art) => {
            const p = saalplan.preise.find((p) => p.preiskategorie == kat.id && p.ticketart == art.id)?.preis
            return { id: art.id , name: `${art.name} (${p} €)`, preis: p  }
          })
        })
        arten[kat.id] = arten2
      })
      return arten
    },
    saalplanBuchungenByPlatz() {
      const buchungen = {}
      this.saalplanBuchungen.forEach((b) => {
        buchungen[b.platz] = true
      })
      return buchungen
    },
  },
  methods: {
    platzName(platz) {
      let name = ''
      if (platz.block) {
        name += ` Block: ` + platz.block
      }
      if (platz.reihe) {
        name += ` Reihe: ` + platz.reihe
      }
      if (platz.platz) {
        name += ` Platz: ` + platz.platz
      }
      if (name) {
        return `${name} (${platz.sitzart})`
      } else {
        return platz.sitzart || ''
      }
    },
    getTicketArtenByIdStuhlPreis(buchung) {
      const pk = this.ticketArtenByIdStuhlPreis[buchung.preiskategorie]
      return pk && pk[buchung.stuhlart] || []
    },
    platzFrei(platz, minPlaetze, direction) {
      let plaetze = 0
      let connected = true
      while (platz) {
        platz = platz[direction]
        if (!platz || platz.reserved || plaetze >= minPlaetze) {
          break
        }
        if (platz.selected) {
          if (!connected) {
            break
          }
        } else {
          connected = false
          plaetze++
        }
      }
      return plaetze
    },
    stuhlart(id) {
      return this.stuhlarten.find((s) => s.id == id)?.name
    },
    hatKeineBestellungen(gk) {
      return !gk.gkBestellungen.some((b) => {
        return b.plaetze.some((p) => !p.storno && p.sitzart != "TN-Platz")
      })
    },
    clearBestellungen(clear) {
      if (clear) {
        Object.values(this.editGk.preise).forEach((kat) => {
          Object.values(kat).forEach((art) => {
            art.anzahl = 0
          })
        })
      }
    },
    isPersonalisierbar(gk, platz) {
      if (platz) {
        return gk.saalplan.ticketarten.find((t) => t.name == platz.ticketart )?.personalisierung
      } else {
        return !!gk.saalplan.ticketarten.find((t) => t.personalisierung && gk.gkBestellungen.find((bestellung) => bestellung.plaetze.find((p) => t.name == p.ticketart)))
      }
    },
    closeGkModal() {
      if (this.editGkedit == 'remove') {
        this.editGk.gkBestellungen.forEach((b) => {
          b.plaetze.forEach((p) => p.destroy = false)
        })
      }
      if (this.editGkedit == 'new') {
        Object.values(this.editGk.preise).forEach((p) => {
          Object.values(p).forEach((p) => p.anzahl = 0)
        })
      }
      if (this.editGkedit != 'names') {
        this.editGkedit = 'names'
      }  else {
        if (this.newGks) {
          this.successMsg = this.newGks
          this.newGks = null
        }
        this.editGkI = null
      }
    },
    ticketAnzahl(anzahlen, all) {
      if (all) {
        return this.editPlaetze.length + sum(Object.values(anzahlen).map((a) => sum(Object.values(a).map((x) => x.anzahl))))
      } else {
        return sum(Object.values(anzahlen).map((x) => x.anzahl))
      }
    },
    ticketPreis(anzahlen, all) {
      if (all) {
        const saalplan = this.editGk.saalplan
        let plaetze = sum(this.editPlaetze.map((pl) => {
          return saalplan.preise.find((pr) => pr.preiskategorie == pl.preiskategorie && pr.ticketart == pl.art)?.preis
        }))
        return plaetze + sum(Object.values(anzahlen).map((a) => sum(Object.values(a).map((x) => x.anzahl * x.p))))
      } else {
        return sum(Object.values(anzahlen).map((x) => x.anzahl * x.p))
      }
    },
    updateReserviertePlaetze(saalplan, saalplanBuchungen) {
      if (!saalplan || !saalplanBuchungen) {
        return
      }
      const blockNameById = this.blockNameById
      const buchungen = saalplanBuchungen.map((b) => b.platz)
      console.log(buchungen);
      const myBuchungen = this.editGk?.saalplanBuchungen?.map((b) => b.platz) || []
      console.log(myBuchungen);
      this.editPlaetze.forEach((platz) => {
        const platzName = `${blockNameById[platz.block] || ''}/${platz.reihe || ''}/${platz.platznummer || ''}`
        myBuchungen.push(platzName)
      })
      console.log(myBuchungen);
      saalplan.plaetze.forEach((platz) => {
        const platzName = `${blockNameById[platz.block] || ''}/${platz.reihe || ''}/${platz.platznummer || ''}`
        const myPlatz = myBuchungen.indexOf(platzName) > -1
        if (myPlatz) {
          // platz.selected = true
          platz.reservedMe = true
          platz.reserved = true
        } else {
          platz.reserved = buchungen.indexOf(platzName) > -1
          platz.reservedMe = false
        }
      })
      console.log(saalplan.plaetze.map((p) => `${p.selected} ${p.reserved} ${p.block}/${p.reihe}/${p.platznummer}`));
      console.log(saalplan.plaetze);
    },
    platzWaehlen(buchung) {
      this.editBuchung = buchung
      // this.$vuetify.goTo(this.$refs.saalplan, { container: this.$refs.dialog.$el.firstChild })
    },
    removeBuchung(buchungI, buchung) {
      this.removeBuchungPlatz(buchung)
      const buchungen = this.editGk.saalplanBuchungen
      buchungen.splice(buchungI, 1)
    },
    hatPlatzwahl(artId) {
      const art = this.ticketartFromId(artId)
      if (!art) {
        return false
      }
      const stuehle = art.stuhlarten
      return !!this.editGk?.saalplan?.stuhlarten?.some((s) => s.platzwahl && stuehle.some((id) => id == s.id))
    },
    platzText(platz) {
      const p = platz?.split("/")
      if (p?.length != 3) {
        return p || ""
      } else {
        return `Block: ${p[0]}, Reihe: ${p[1]}, Platz: ${p[2]}`
      }
    },
    ticketartFromId(artId) {
      return this.editGk?.saalplan?.ticketarten?.find((art) => art.id == artId)
    },
    artText(artId) {
      return this.ticketartFromId(artId)?.name || "Unbekannt"
    },
    addPlatz(art) {
      const gk = this.editGk
      const buchung = { art: art.id, platz: null }
      gk.saalplanBuchungen.push(buchung)
      gk.saalplanBuchungen = sortBy(gk.saalplanBuchungen, ['art', 'platz'])
      this.platzWaehlen(buchung)
    },
    preisFor(gk, art) {
      let preise = sortBy(gk.saalplan.preise.filter((p) => p.preis != null && p.ticketart == art.id), 'preis')
      if (preise.length == 0) {
        return 'Nope'
      } else if (preise.length == 1) {
        return `${preise[0].preis}`
      } else {
        return `${preise[0].preis}-${preise[preise.length - 1].preis}`
      }
      // preise.sor
      // this.preise.find((preis) => {
      //   if (preis.ticketart == gk.ticketart) {
      //     if (preis.preis < min) {
      //       min = preis.preis
      //       minPreis = preis
      //     }
      //     if (preis.preis > max) {
      //       max = preis.preis
      //       maxPreis = preis
      //     }
      //   }
      // })
      // if (maxPreis && max != min) {
      //   return { }
      // }
    },
    copySaalplanUnmodified(old) {
      // const buchungen = this.editGk.termin.saalplanBuchungen.map((b) => b.platz)
      const buchungen = this.saalplanBuchungen?.map((b) => b.platz) || []
      const myBuchungen = this.editGk.saalplanBuchungen.map((b) => b.platz)
      const plaetze = old.plaetze.map((p) => ({ ...p, id: +p.id, nachbar: p.nachbar ? +p.nachbar : p.nachbar, selected: false, reserved: false, remembered: false }))
      plaetze.forEach((platz) => {
        const nextId = platz.id + 1
        const platzName = `${platz.block || ''}/${platz.reihe || ''}/${platz.platznummer || ''}`
        const myPlatz = myBuchungen.indexOf(platzName) > -1
        if (myPlatz) {
          platz.selected = true
        } else if (buchungen.indexOf(platzName) > -1) {
          platz.reserved = true
        }
        platz.next = plaetze.find(p => p.id == nextId)
        platz.nachbarL = plaetze.find(p => p.id == platz.nachbar)
        platz.nachbarR = plaetze.find(p => p.nachbar == platz.id)
      })
      return {
        ...old,
        width: `${old.width}`,
        height: `${old.height}`,
        scale: `${old.scale}`,
        plaetze,
        zugaenge: old.zugaenge.map((r) => ({ ...r })),
        bloecke: old.bloecke.map((r) => ({ ...r })),
        texte: old.texte.map((t) => ({ ...t, selected: false })),
        stuhlarten: old.stuhlarten.map((r) => ({ ...r })),
        ticketarten: old.ticketarten.map((r) => ({ ...r })),
        preise: old.preise.map((r) => ({ ...r })),
      }
    },
    removeBuchungPlatz(buchung) {
      const plaetze = this.saalplan.plaetze
      const pl = buchung.platz?.split("/") || []
      const platz = plaetze.find((p) => p.block == pl[0] && p.reihe == pl[1] && p.platznummer == pl[2] )
      if (platz) {
        platz.selected = false
      }
    },
    platzSelected(platz) {
      if (platz) {
        const buchung = { ...platz, next: undefined }
        const plaetze = this.saalplan.plaetze
        const saalPlatz = plaetze.find((p) => p.id == platz.id)
        const editPlaetze = this.editPlaetze
        if (!saalPlatz.reserved || saalPlatz.selected) {
          if (!saalPlatz.selected) {
            saalPlatz.selected = true
            const pk = this.editGk.saalplan.preiskategorien.find((pk)=> pk.id == buchung.preiskategorie)
            buchung.color = pk.color
            buchung.preiskategorieName = pk.name
            buchung.art = this.ticketarten[0]?.id || null
            editPlaetze.push(buchung)
          } else {
            saalPlatz.selected = false
            const i = editPlaetze.findIndex((b) => b.id == platz.id)
            editPlaetze.splice(i, 1)
          }
        }
      }
      // if (platz && platz.reserved && platz.selected && !platz.reservedMe) {

      // }
      // if (platz && !platz.reserved) {
      //   const buchePlaetze = this.anzahlPlaetze
      //   const plaetze = this.saalplan.plaetze
      //   plaetze.forEach((p) => {
      //     p.selected = false
      //   })

      //   let max = plaetze.length
      //   let newPlatz = platz
      //   for (let i=0; i<buchePlaetze && max >= 0; max--) {
      //     if (newPlatz) {
      //       if (newPlatz.platznummer && !newPlatz.reserved && !newPlatz.selected) {
      //         newPlatz.selected = true
      //         i++
      //       }
      //       newPlatz = newPlatz.next || plaetze.find((p) => p.id == 1)
      //     }
      //   }

      //   this.editPlatz = platz
      // }
    },
    hasStreamer(gk) {
      return !!(gk && gk.termin.ticketpreis.streamer)
    },
    mgStrToArray(mitgliederNamen, count = null) {
      const mgs = []
      if (!mitgliederNamen) {
        return mgs
      }
      ;(mitgliederNamen||'').split("\n").forEach(line => {
        const parts = line.split(",")
        mgs.push({
          vorname: parts.shift() || '',
          nachname: parts.shift() || '',
          mgNr: parts.join(" ") || ''
        })
      })
      if (count !== null) {
        while (mgs.length < count) {
          mgs.push({ vorname: '', nachname: '', mgNr: '' })
        }
        while (mgs.length > count) {
          mgs.pop()
        }
      }
      return mgs
    },
    mgArrayToStr(mgNamen) {
      return mgNamen.map(mg => `${mg.vorname.replace(/,/, ' ')},${mg.nachname.replace(/,/, ' ')},${mg.mgNr.replace(/,/, ' ')}`.replace(/[, ]+$/, '')).join("\n")
    },
    preisGesamt(gaestekarte) {
      const gast = (gaestekarte.erwachsene - 0) * gaestekarte.termin.ticketpreis.gast || 0
      const kind = (gaestekarte.kinder - 0) * gaestekarte.termin.ticketpreis.gastKind || 0
      const streamer = (gaestekarte.streamer - 0) * gaestekarte.termin.ticketpreis.streamer || 0
      return gast + kind + streamer
    },
    gesamtAnzahl(gaestekarte) {
      return (gaestekarte.erwachsene - 0) + (gaestekarte.kinder - 0) + (gaestekarte.streamer - 0) + (gaestekarte.mitglieder - 0)
    },
    strftime(date, format) {
      if (date) {
        return moment(date).format(format)
      } else {
        return ''
      }
    },
    refreshSaalplan() {
      this.$apollo.queries.user.refetch().then((response) => {
        const saalplan = this.saalplaene && this.saalplaene[0]
        if (saalplan) {
          const gkId = this.editGk.id
          const gk = response.data.user.gaestekarten.find((gk) => gk.id == gkId)
          if (gk) {
            this.editGk.termin.saalplanBuchungen = gk.termin.saalplanBuchungen || []
          }
          this.saalplan = this.copySaalplanUnmodified(saalplan)
        }
      })
    },
    save(gaestekarte, kategorie) {
      let neuePlaetze = []
      Object.entries(gaestekarte.preise).forEach(([kategorie, kat]) => {
        Object.entries(kat).forEach(([ticketart, { anzahl }]) => {
          for (let i=0; i<anzahl; ++i) {
            neuePlaetze.push({ kategorie, ticketart })
          }
        })
      })
      const saalplan = this.saalplan
      this.editPlaetze.forEach((platz) => {
        neuePlaetze.push({
          kategorie: saalplan.preiskategorien.find((p) => p.id == platz.preiskategorie).name,
          ticketart: saalplan.ticketarten.find((a) => a.id == platz.art).name,
          bestplatz: `${platz.id || ''}`,
          platz: platz.platznummer,
          reihe: platz.reihe,
          block: platz.block,
          zugang: platz.zugang,
          sitzart: saalplan.stuhlarten.find((s) => s.id == platz.stuhlart).name,
        })
      })

      let editPlaetze = []
      gaestekarte.gkBestellungen.forEach((buchung) => {
        buchung.plaetze.forEach((platz) => {
          editPlaetze.push({
            ...platz,
            __typename: undefined,
          })
        })
      })

      this.nameAnPresseError = []
      this.loading++
      this.gqlClearErrorsFor('gaestekarteOrder')
      this.$apollo.mutate({
        mutation: gql`mutation jfGaestekartenVueSave(
          $id: ID
          $terminId: ID
          $bestellungId: ID
          $anmerkungen: String
          $rollstuhl: Boolean
          $nameAnPresse: Boolean
          $nameAnPresseAngegeben: Boolean
          $neuePlaetze: [GaestekartePlatzInput]
          $editPlaetze: [GaestekartePlatzInput]
          $ichMoechteKeineKarten: Boolean
        ) {
          gaestekarteOrder: gaestekarteOrderBestplatz(
            id: $id
            terminId: $terminId
            bestellungId: $bestellungId
            anmerkungen: $anmerkungen
            rollstuhl: $rollstuhl
            nameAnPresse: $nameAnPresse
            nameAnPresseAngegeben: $nameAnPresseAngegeben
            neuePlaetze: $neuePlaetze
            editPlaetze: $editPlaetze
            ichMoechteKeineKarten: $ichMoechteKeineKarten
          ) {
            id
            anmerkungen
            rollstuhl
            nameAnPresse
            ichMoechteKeineKarten
            gkBestellungen {
              id
              status
              plaetze {
                id
                kategorie
                ticketart
                sitzart
                platz
                vorname
                nachname
                kontakt
                preis
              }
            }
          }
        }`,
        variables: {
          id: gaestekarte.id,
          terminId: gaestekarte.terminId,
          bestellungId: gaestekarte.bestellungId,
          anmerkungen: gaestekarte.anmerkungen,
          rollstuhl: gaestekarte.rollstuhl,
          nameAnPresse: gaestekarte.nameAnPresse,
          nameAnPresseAngegeben: this.editGkedit == 'names',
          neuePlaetze,
          editPlaetze,
          ichMoechteKeineKarten: gaestekarte.ichMoechteKeineKarten,
        }
      }).then(response => {
        this.loading--
        console.log("saved: ", this.editGkedit)
        if (!this.editGk.id) {
          this.editGk.id = response.data.gaestekarteOrder.id
        }
        // const saalplan = this.saalplaene && this.saalplaene[0]
        // if (saalplan) {
          // this.refreshSaalplan()
          if (this.editGkedit == 'new') {
            const gkId = this.editGk.id
            const gk = this.gaestekarten.find((g) => g.id == gkId)
            this.editGk = gk
            this.editGkedit = "names"
            if (kategorie) {
              this.newGks = gaestekarte
            }
            // const gki = this.editGki
            // this.editGki = null
            // this.$nextTick(() => {
            //   this.$nextTick(() => {
            //     this.editGkI = gki
            //     this.editGkedit = "names"
            //   })
            // })
          // } else if (this.showSaalplan) {
          //   this.$store.commit("addError", { text: "Ein Fehler ist aufgetreten" })
          //   // this.savePlaetze()
          } else if (this.editGkedit == "remove") {
            const gkId = this.editGk.id
            const gk = this.gaestekarten.find((g) => g.id == gkId)
            this.editGk = gk
            this.editGkedit = "names"
          } else if (this.editGkedit == "names") {
            this.editGkI = null
            this.editGkedit = null
            if (this.newGks) {
              this.successMsg = gaestekarte
            }
          }
          // this.refreshSaalplan()
          console.log("saved end:", this.editGkedit, this.editGk?.id, this.editGkI)
          // }
        // } else {
        //   console.log("saved: no saalplan: ", this.editGkedit)
        //   this.$store.commit("addError", { text: "Ein Fehler ist aufgetreten" })
        // }
        this.$vuetify.goTo(this.$refs.top)
      }, err => {
        // this.gqlAddErrors(err, ['gaestekarteOrder', 'base'])
        console.log("errr", err);
        this.gqlAddErrors(err, 'gaestekarteOrder')
        this.$vuetify.goTo(this.$refs.top)
        this.loading--
        // this.refreshSaalplan()
      })
    },
    savePlaetze() {
      this.loading++
      this.gqlClearErrorsFor('gaestekarteOrder')
      this.$apollo.mutate({
        mutation: gql`mutation jfGaestekartenVueSavePlaetze(
          $id: ID!
          $saalplanBuchungen: [String]!
        ) {
          gaestekarteOrder: gaestekarteOrderPlaetze(
            id: $id
            saalplanBuchungen: $saalplanBuchungen
          ) {
            id
            saalplanBuchungen {
              id
              platz
            }
          }
        }`,
        variables: {
          id: this.editGk.id,
          saalplanBuchungen: this.editGk.saalplanBuchungen.map((buchung) => {
            return `${buchung.art};:;${buchung.platz}`
          })
        }
      }).then(response => {
        this.loading--
        this.successMsg = this.editGk
        this.editGkI = null
        this.saalplan = null
        this.$apollo.queries.user.refetch()
        this.$vuetify.goTo(this.$refs.top)
      }, err => {
        this.gqlAddErrors(err, ['gaestekarteOrder', 'base'])
        this.refreshSaalplan()
        this.$vuetify.goTo(this.$refs.top)
        this.loading--
      })
    }
  }
}
</script>
