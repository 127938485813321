<template lang="pug">
component(:is="inline ? 'div' : 'v-row'" :class="inline ? '' : 'justify-center'")
  component(:is="inline ? 'div' : 'v-col'" cols="12" md="6" xl="4")
    div(v-html="html" v-if="html")
    template(v-if="!$store.getters.isLoggedIn")
      v-form(@submit="anmelden")
        v-alert.mb-6(:value="!!loginError" type="error") {{loginError}}
        v-alert.mb-6(:value="!!message" type="success") {{message}}
        v-text-field(label="E-Mail *" v-if="!passwordToken && !confirmationInvalid && !unlockInvalid" autocomplete="email" name="email" v-model="email" @blur="email = email.toLowerCase()" :error-messages="gqlErrorsFor('userReset', 'email')" persistent-hint)

        template(v-if="extraAction")
          template(v-if="passwordreset")
            template(v-if="passwordresetCount > 0")
              | Bitte schauen sie in ihre E-Mails und Klicken Sie auf den Link um Ihr Passwort zurücksetzen. Es kann mehrere minuten dauern bis die E-Mail ankommt. Schauen sie auch im Spam nach.
            v-btn.mt-5.mb-8(color="primary" outlined x-large type="submit" :loading="loading > 0" :disabled="passwordresetCount > 0") Mein Passwort zurücksetzen
          template(v-else-if="passwordToken")
            template(v-if="$apollo.queries.pwTokenError.loading")
            template(v-else-if="pwTokenError")
              v-alert(:value="true" type="error")
                p.text-wrap-lines {{pwTokenError}}
            template(v-else)
              v-alert.mb-6(:value="gqlErrorsFor('userReset', 'reset_password_token').length > 0" type="error") {{gqlErrorsFor('userReset', 'reset_password_token').join(", ")}}
              v-text-field(label="Neues Passwort" v-model="password" autocomplete="new-password" type="password" :error-messages="gqlErrorsFor('userReset', 'password')")
              v-text-field(label="Passwort Bestätigen" v-model="passwordConfirmation" autocomplete="new-password" type="password" :error-messages="gqlErrorsFor('userReset', 'password_confirmation')")
              v-btn.btn-multiline.mt-5.mb-8(color="primary" outlined x-large type="submit" :loading="loading > 0" :disabled="confirmationCount > 0") Jetzt Ändern
          template(v-else-if="confirmationInvalid")
              v-alert.mb-6(:value="true" type="error")
                p Überprüfen Sie bitte, ob Sie die gesamte Link-URL aus der Account-Bestätigen-E-Mail verwendet haben.
                p Der Link funktioniert nur einmal, wenn der Account bereits Bestätigt ist können sie sich einloggen.
          template(v-else-if="unlockInvalid")
            template(v-if="unlockInvalid == 2")
              v-alert.mb-6(:value="true" type="success") Account entsperrt. Sie können sich jetzt Einloggen.
            template(v-else)
              v-alert.mb-6(:value="true" type="error")
                p Überprüfen Sie bitte, ob Sie die gesamte Link-URL aus der Entsperr-E-Mail verwendet haben.
                p Falls Sie mehrere Entsperr-E-Mails ausgelöst haben, stellen Sie sicher, dass Sie die Link-URL aus der letzten E-Mail verwendet haben.
          template(v-else-if="unconfirmed")
            template(v-if="confirmationCount > 0")
              | Bitte schauen sie in ihre E-Mails und Klicken Sie auf den Link um Ihren Account zu aktivieren. Es kann mehrere minuten dauern bis die E-Mail ankommt. Schauen sie auch im Spam nach.
            v-btn.btn-multiline.mt-5.mb-8(color="primary" outlined x-large type="submit" :loading="loading > 0" :disabled="confirmationCount > 0") Bestätigungslink erneut senden
          template(v-else-if="locked")
            template(v-if="unlockCount > 0")
              | Bitte schauen sie in ihre E-Mails und Klicken Sie auf den Link um Ihren Account zu entsperren. Es kann mehrere minuten dauern bis die E-Mail ankommt. Schauen sie auch im Spam nach.
            v-btn.btn-multiline.mt-5.mb-8(color="primary" outlined x-large type="submit" :loading="loading > 0" :disabled="unlockCount > 0") Entsperrlink erneut senden
          br
          a(@click="reset" href="#") Einloggen
        template(v-else)
          v-text-field(label="Passwort *" autocomplete="current-password" v-model="password" type="password" :error-messages="gqlErrorsFor('userReset', 'password')")
          v-btn.mt-5.mb-8(color="primary" outlined x-large type="submit" :loading="loading > 0") Einloggen
          br
          template(v-if="!inline")
            router-link(to="/") Zur Teilnehmer Anmeldung
            br
          a(@click="reset($event, {passwordreset: true })" href="#") Passwort vergessen?

    template(v-else)
      | Bereits angemeldet als {{$store.state.user.email}}

</template>

<script>
import gql from 'gql-id.macro'
import graphqlErrorObject from '../backend/mixins/graphql-error-object.js'
import QueryFilter from '../backend/mixins/query-filter'

export default {
  mixins: [graphqlErrorObject, QueryFilter],
  props: {
    value: Object,
    inline: Boolean,
  },
  data() {
    return {
      loading: 0,
      confirmationCount: 0,
      passwordresetCount: 0,
      unlockCount: 0,
      loginError: null,
      message: null,
      email: '',
      password: '',
      passwordConfirmation: '',
      lastEmail: '',
    }
  },
  mounted() {
    this.lastEmail = ''
    const getters = this.$store.getters
    if (getters.isLoggedIn && !getters.editPage) {
      this.$router.replace('/')
      this.$nextTick(() => {
        this.$store.commit('addError', { text: "Bereits angemeldet", timeout: 5000 })
      })
    }
  },
  apollo: {
    pwTokenError: {
      query: gql`query jfLoginVuePw($token: String!) {
        pwTokenError(token: $token)
      }`,
      variables() {
        return { token: this.passwordToken }
      },
      skip() {
        return !this.passwordToken
      }
    }
  },
  computed: {
    html() {
      return this.value?.html || ''
    },
    unconfirmed() {
      return this.loginError && /Ihren Account mit E-Mail best/.test(this.loginError)
    },
    locked() {
      return this.loginError && /Ihr Account ist gesperrt/.test(this.loginError )
    },
    sameEmail() {
      return !!(this.lastEmail && this.lastEmail === this.email)
    },
    passwordToken: {
      get() {
        return this.$route.query.token || null
      },
      set(value) {
        this.queryFilterUpdates({ token: value || null }, false, { push: true })
      }
    },
    passwordreset: {
      get() {
        return this.$route.query.passwordreset == '1'
      },
      set(value) {
        this.queryFilterUpdates({ passwordreset: value ? '1' : null }, false, { push: true })
      }
    },
    confirmationInvalid: {
      get() {
        return this.$route.query.confirmationInvalid == '1'
      },
      set(value) {
        this.queryFilterUpdates({ confirmationInvalid: value ? '1' : null }, false, { push: true })
      }
    },
    unlockInvalid: {
      get() {
        return this.$route.query.unlockInvalid
      },
      set(value) {
        this.queryFilterUpdates({ unlockInvalid: value }, false, { push: true })
      }
    },
    extraAction() {
      return this.passwordreset || this.passwordToken || this.confirmationInvalid || this.unlockInvalid || (this.sameEmail && (this.unconfirmed || this.locked))
    },
  },
  methods: {
    reset(event, options) {
      if (event?.preventDefault) {
        event.preventDefault()
      }
      this.message = null
      this.loginError = null
      if (options?.passwordreset) {
        this.passwordreset = true
      } else {
        this.passwordreset = false
        this.passwordToken = null
        this.confirmationInvalid = null
        this.unlockInvalid = null
      }
    },
    async anmelden(event) {
      event?.preventDefault()
      const email = this.email
      this.lastEmail = email
      this.message = null
      if (this.extraAction) {
        const variables = {
          email,
          passwordreset: this.passwordreset,
          confirmation: this.unconfirmed,
          unlock: this.locked,
          passwordToken: this.passwordToken,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
        }
        this.gqlAction({
          loading: 'loading',
          mutationName: 'userReset',
          variables,
          then(response) {
            if (response?.data?.userReset?.message) {
              this.message = response.data.userReset.message
              if (this.passwordreset) {
                this.passwordresetCount++
              } else if (this.unconfirmed) {
                this.confirmationCount++
              } else if (this.locked) {
                this.unlockCount++
              }
              this.passwordreset = false
              this.passwordToken = null
              this.password = ''
              this.passwordConfirmation = ''
            }
          },
          finally() {
            this.loginError = null
          },
          mutation: gql`
            mutation jfLoginVueUserReset(
              $email: String
              $passwordreset: Boolean
              $confirmation: Boolean
              $unlock: Boolean
              $passwordToken: String
              $password: String
              $passwordConfirmation: String
            ) {
              userReset(
                email: $email
                passwordreset: $passwordreset
                confirmation: $confirmation
                unlock: $unlock
                passwordToken: $passwordToken
                password: $password
                passwordConfirmation: $passwordConfirmation
              ) {
                success
                message
              }
            }`,
        })
      } else {
        this.loading++
        this.$store.dispatch('login', { email: email, password: this.password, to: this.inline ? null : '/account' }).then(() => {
          this.loading--
          this.loginError = null
        }, async (response) => {
          this.loading--
          if (response.error) {
            this.loginError = response.error
          } else {
            this.loginError = null
          }
        })
      }
    },
  },
}
</script>
