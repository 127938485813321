import { VCheckbox } from 'vuetify2/lib'

export default {
  name: 'accept-datenschutz',
  extends: VCheckbox,
  methods: {
    genMyLabel() {
      const label = this.genLabel()
      if (label) {
        return label
      } else {
        return this.$createElement('span', { on: { click: this.onChange }}, [
          '* Ich erkläre mich einverstanden, dass der HVD Berlin-Brandenburg KdöR die von mir angegebenen Daten zum Zwecke der JugendFEIER speichert und verarbeitet ',
          this.$createElement('a', { on: { click: e => e.stopPropagation() }, attrs: { href: "/datenschutz", target: "_blank" } }, '(Datenschutzerklärung)'), '.'
        ])
      }
    },
    genDefaultSlot() {
      return [
        this.genCheckbox(),
        this.genMyLabel()
      ]
    }
  }
}
