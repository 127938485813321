import Vue from 'vue'
import store from './index'

const menuAll = window.dataVueApp?.menus || []
const menuSlugs = menuAll.filter((m) => m.slug != null && m.kind != 'Weiterleitung').map((m) => m.slug.replace(/[{}]/g, ''))
const menuNames = { orphanMenus: [] }
menuAll.forEach((menu) => {
  if (menu.kind == "System") {
    menuNames[menu.name] = []
  }
})

const pageParts = Vue.observable({ parts: window.dataVueApp?.currentMenu?.pageParts || [] })
const menuOptions = { $http: null, $apollo: null, $router: null }
const menuByName = Vue.observable(menuNames)
const currentMenu = Vue.observable({ id: window.dataVueApp?.currentMenu?.id || null })

function menuUrl(menu) {
  if (menu.kind == 'Seite') {
    return `/${menu.slug.replace(/[{}]/g, '')}`
  } else if (menu.kind == 'Link' || menu.kind == 'Weiterleitung') {
    return menu.url
  } else if (menu.kind == 'Logout') {
    return menu.url || '/'
  }
}

async function activateMenu(menu, event) {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }
  if (menu.kind == 'Logout') {
    menuOptions.$http.delete('/admin/logout.json').then(response => {
      menuOptions.$apollo.provider.defaultClient.resetStore()
      window.location = menuUrl(menu)
    }, error => {
      console.error(error)
    })
  } else {
    const url = menuUrl(menu)
    if (url) {
      if (menuSlugs.indexOf(url.replace(/^\//, '')) > -1) {
        menuOptions.$router.push(url)
      } else {
        window.location = url
      }
    }
  }
}

function menuItemVisible(login) {
  const visible = ["Immer"]
  if (login == "Angemeldet") {
    visible.push("Angemeldet")
  } else if (login == "Mitarbeiter") {
    visible.push("Angemeldet")
    visible.push("Mitarbeiter")
  } else if (login == "Admin") {
    visible.push("Angemeldet")
    visible.push("Mitarbeiter")
    visible.push("Admin")
  } else {
    visible.push("Abgemeldet")
  }
  return visible
}

function menuCalculate(breakpoint, login, menus = {}) {
  const currentSize = typeof breakpoint == "string" ? ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(breakpoint) : breakpoint
  const orphanMenus = []
  const visible = menuItemVisible(login)
  const allMenus = menuAll.map((m) => ({ ...m, children: [] }))
  allMenus.forEach((item) => {
    if (item.kind == "System") {
      menus[item.name] = item
    } else {
      if (visible.indexOf(item.visibility) > -1) {
        let parentId = null
        if (item.moveOnSizes.indexOf(currentSize) > -1) {
          parentId = item.moveTo
        } else {
          parentId = item.parentId
        }
        if (parentId) {
          const parent = allMenus.find((m) => m.id == parentId)
          if (parent) {
            parent.children.push(item)
          } else {
            orphanMenus.push(item)
          }
        } else {
          orphanMenus.push(item)
        }
      } else {
        orphanMenus.push(item)
      }
    }
  })
  menus.orphanMenus = { orphanMenus: true, name: "…", position: undefined, children: orphanMenus }
  return menus
}

function menuShow() {
  if (store.getters.isAdmin) {
    return 'Admin'
  } else if (store.getters.isMitarbeiter) {
    return 'Mitarbeiter'
  } else if (store.state.user) {
    return 'Angemeldet'
  } else {
    return 'Abgemeldet'
  }
}

function menuRecompute(breakpoint) {
  menuCalculate(breakpoint, menuShow(), menuByName)
}

export { menuOptions, menuCalculate, menuShow, menuRecompute, activateMenu, menuUrl, pageParts, currentMenu, menuAll }
export default menuByName
